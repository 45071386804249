import {
  CHECK_USER_BEGIN,
  CHECK_USER_SUCCESS,
  CHECK_USER_FAILURE,
  USER_FORM_CHANGE,
  ONLINE_ORDER_BEGIN,
  ONLINE_ORDER_SUCCESS,
  ONLINE_ORDER_FAILURE,
  RESET_ORDER,
  EDIT_USER,
  USER_DELIVER_FORM_CHANGE,
  CLEAR_STORAGE,
  GET_SUPERCATEGORY_BEGIN,
  GET_SUPERCATEGORY_FAILURE,
  GET_SUPERCATEGORY_SUCCESS,
  PAYMENT_HITTING_BEGIN,
  PAYMENT_HITTING_SUCCESS,
  PAYMENT_HITTING_ERROR,
  RESET_PAYMENT_HITTING,
  TRANSACTION_CHECK_BEGIN,
  TRANSACTION_CHECK_SUCCESS,
  TRANSACTION_CHECK_ERROR,
  ORDER_TRANSACTION_CHECK_BEGIN,
  ORDER_TRANSACTION_CHECK_SUCCESS,
  ORDER_TRANSACTION_CHECK_ERROR,
  // ORDER_PAYMENT_CHECK_BEGIN,
  // ORDER_PAYMENT_CHECK_SUCCESS,
  // ORDER_PAYMENT_CHECK_ERROR,
  ONLINE_TABLEORDER_SUCCESS,
  SAVE_TABLE_BEGIN,
  SAVE_TABLE_SUCCESS,
  SAVE_TABLE_ERROR,
  // RESET_ORDER_PAYMENT_CHECK,
  PAYMENT_AND_ORDER_STATUS
} from "../actions";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  orderType: "",
  loading: false,
  userChecked: false,
  userId: "",
  activeId: 1,
  supercategoryLoading: false,
  supercategoryError: false,
  supercategoryData: {},
  paymentHittingLoading: false,
  paymentHittingSuccess: false,
  paymentHittingError: false,
  paymentHittingData: {},
  transactionCheckLoading: false,
  transactionCheckSuccess: false,
  transactionCheckError: false,
  transactionCheckData: {},
  orderSuccess: false,
  orderError: false,
  orderData: {},
  ordertransactionCheckLoading: false,
  ordertransactionCheckSuccess: false,
  ordertransactionCheckError: false,
  ordertransactionCheckData: {},
  orderPaymentCheckLoading: false,
  orderPaymentCheckSuccess: false,
  orderPaymentCheckError: false,
  orderPaymentCheckData: {},
  tableNumber: 0,
  saveTableCheckLoading: false,
  saveTableCheckSuccess: false,
  saveTableCheckError: false,
  tableError: "",
  otp: ""
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ORDER:
      return {
        ...state,
        activeId: 1
      };
    case USER_FORM_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      };
    case USER_DELIVER_FORM_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      };
    case EDIT_USER:
      return {
        ...state,
        userChecked: false
      };
    case CHECK_USER_BEGIN:
      return {
        ...state,
        loading: true
      };
    case CHECK_USER_SUCCESS:
      console.log("id", action.user._id);
      return {
        ...state,
        loading: false,
        userId: action.user._id,
        userChecked: true
      };
    case CHECK_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case ONLINE_ORDER_BEGIN:
      return {
        ...state,
        loading: true,
        orderType: action.ordertype,
        orderSuccess: false,
        orderError: false,
        orderData: {}
      };
    case ONLINE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        otp: action.payload.otp,
        activeId: 2,
        orderSuccess: true,
        orderError: false,
        orderData: action.payload.id,
        orderType: action.payload.orderType,
        driveoutCharge: action.payload.driveoutCharge,
        tax: action.payload.tax
      };
    case ONLINE_TABLEORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        activeId: 2,
        orderSuccess: true,
        orderError: false,
        orderData: action.payload.id,
        orderType: action.payload.orderType,
        tax: action.payload.tax
      };

    case ONLINE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        orderSuccess: false,
        orderError: true,
        orderData: {}
      };
    case GET_SUPERCATEGORY_BEGIN:
      return {
        ...state,
        supercategoryLoading: true,
        supercategorySuccess: false,
        supercategoryData: {}
      };
    case GET_SUPERCATEGORY_SUCCESS:
      return {
        ...state,
        supercategoryLoading: false,
        supercategorySuccess: true,
        supercategoryData: action.data
      };
    case GET_SUPERCATEGORY_FAILURE:
      return {
        ...state,
        supercategoryLoading: false,
        supercategoryData: {},
        supercategoryError: false
      };
    case PAYMENT_HITTING_BEGIN:
      return {
        ...state,
        paymentHittingLoading: true,
        paymentHittingSuccess: false,
        paymentHittingError: false,
        paymentHittingData: {}
      };
    case PAYMENT_HITTING_SUCCESS:
      return {
        ...state,
        paymentHittingLoading: false,
        paymentHittingSuccess: true,
        paymentHittingError: false,
        paymentHittingData: action.data
      };
    case PAYMENT_HITTING_ERROR:
      return {
        ...state,
        paymentHittingLoading: false,
        paymentHittingSuccess: false,
        paymentHittingError: true,
        paymentHittingData: action.data
      };
    case RESET_PAYMENT_HITTING:
      return {
        ...state,
        paymentHittingLoading: false,
        paymentHittingSuccess: false,
        paymentHittingError: false,
        payentHititngData: {}
      };

    case TRANSACTION_CHECK_BEGIN:
      return {
        ...state,
        transactionCheckLoading: true,
        transactionCheckSuccess: false,
        transactionCheckError: false,
        transactionCheckData: {}
      };
    case TRANSACTION_CHECK_SUCCESS:
      return {
        ...state,
        transactionCheckLoading: false,
        transactionCheckSuccess: true,
        transactionCheckError: false,
        transactionCheckData: action.data
      };

    case TRANSACTION_CHECK_ERROR:
      return {
        ...state,
        transactionCheckLoading: false,
        transactionCheckSuccess: false,
        transactionCheckError: true,
        transactionCheckData: action.data
      };
    case ORDER_TRANSACTION_CHECK_BEGIN:
      return {
        ...state,
        ordertransactionCheckLoading: true,
        ordertransactionCheckSuccess: false,
        ordertransactionCheckError: false,
        ordertransactionCheckData: {}
      };
    case ORDER_TRANSACTION_CHECK_SUCCESS:
      return {
        ...state,
        ordertransactionCheckLoading: false,
        ordertransactionCheckSuccess: true,
        ordertransactionCheckError: false,
        ordertransactionCheckData: action.data
      };
    case ORDER_TRANSACTION_CHECK_ERROR:
      return {
        ...state,
        ordertransactionCheckLoading: false,
        ordertransactionCheckSuccess: false,
        ordertransactionCheckError: true,
        ordertransactionCheckData: {}
      };

    case SAVE_TABLE_BEGIN:
      console.log("savetablebegin");
      return {
        ...state,
        saveTableCheckLoading: true,
        saveTableCheckSuccess: false,
        saveTableCheckError: false,
        tableNumber: 0
      };
    case SAVE_TABLE_SUCCESS:
      console.log(action.tableNumber);
      return {
        ...state,
        saveTableCheckSuccess: true,
        saveTableCheckError: false,
        saveTableCheckLoading: false,
        tableNumber: action.tableNumber
      };
    case SAVE_TABLE_ERROR:
      console.log(action.payload.error.message + "messageeeee");
      return {
        ...state,
        saveTableCheckError: false,
        saveTableCheckLoading: false,
        saveTableCheckSuccess: false,
        tableNumber: 0,
        tableError: action.payload.error.message
      };
    case PAYMENT_AND_ORDER_STATUS:
      return {
        ...state,
        paymentHittingSuccess: false,
        orderSuccess: false
      };

    // case ORDER_PAYMENT_CHECK_BEGIN:
    //   return {
    //     ...state,
    //     orderPaymentCheckLoading: true,
    //     orderPaymentCheckSuccess: false,
    //     orderPaymentCheckError: false,
    //     orderPaymentCheckData: {}
    //   };
    // case ORDER_PAYMENT_CHECK_SUCCESS:
    //   return {
    //     ...state,
    //     orderPaymentCheckLoading: false,
    //     orderPaymentCheckSuccess: true,
    //     orderPaymentCheckError: false,
    //     orderPaymentCheckData: action.message
    //   };
    // case ORDER_PAYMENT_CHECK_ERROR:
    //   return {
    //     ...state,
    //     orderPaymentCheckLoading: false,
    //     orderPaymentCheckSuccess: false,
    //     orderPaymentCheckError: true,
    //     orderPaymentCheckData: {}
    //   };

    case CLEAR_STORAGE:
      return { ...initialState };
    default:
      return state;
  }
};

export default order;
