import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Col, Row, notification, TimePicker, Select } from "antd";
import {
  Button,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import globalConst from "../helpers/global";
import { getRestaurantInfo } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { validatePhone, validEmail, validName } from "../helpers/regex";
import moment from "moment";
import countryCodesList from "country-codes-list";
import NotFoundGif from "../assets/notfoundgif.gif";
import TextArea from "antd/lib/input/TextArea";
import { isMobile } from "react-device-detect";
import GetAllData from "./GetAllData";
import { DatePicker } from "antd";
// Assuming you have your countryCodesList available
const myCountryCodesObject = countryCodesList.customList(
  "countryCode",
  "[{countryCode}]:{countryCallingCode}"
);

const allCountryCodes = Object.values(myCountryCodesObject);

// Filter for Norway and India only
const filteredCountryCodes = allCountryCodes.filter(
  (cn) => cn.startsWith("[NO]:47") || cn.startsWith("[IN]:91")
);

const { RangePicker } = DatePicker;

const ReserveTable = (props) => {
  const [dateTime, setDateTime] = useState(null);
  const onChange = (value) => {
    setDateTime(value);
  };
  const slug = props.match.params.slug;
  const [loading, setLoading] = useState(false);
  const [countryCode, setcountryCode] = useState(47);
  const [open, setOpen] = useState(false);
  const [numberOfSeats, setNumberOfSeats] = useState(1);
  const [numberOfSeatsChildren, setNumberOfSeatsChildren] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [reserveTableId, setReserveTableId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [price, setPrice] = useState();
  const [newData, setNewData] = useState([]);
  const [showProducts, setShowProducts] = useState(false);
  const [data, setData] = useState({});

  const dispatch = useDispatch();
  const format = "HH:mm";
  const [formObject, setFormObject] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    date: "", // Ensure date is initialized properly
    time: "",
    note: ""
  });

  const [formError, setFormError] = useState({});

  const {
    id,
    logo,
    name,
    additional,
    error,
    err_status,
    availableSeats,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday
  } = useSelector((state) => state.restaurant);
  // Map days to their respective opening and closing times
  // Get the current day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  // const currentDayIndex = new Date().getDay();

  // Map days to their respective operating hours
  // const operatingHours = {
  //   0: sunday,
  //   1: monday,
  //   2: tuesday,
  //   3: wednesday,
  //   4: thursday,
  //   5: friday,
  //   6: saturday
  // };

  // // Get today's operating hours
  // const { openingTime, closingTime } = operatingHours[currentDayIndex] || {
  //   openingTime: "00:00",
  //   closingTime: "23:59"
  // };

  const openNotification = (message, desc) => {
    notification.open({
      message: message,
      description: desc
    });
  };

  const storedUserData = localStorage.getItem("userData");
  const userData = storedUserData ? JSON.parse(storedUserData) : null;
  useEffect(() => {
    if (userData) {
      setFormObject((prevFormObject) => {
        const updatedFormObject = {
          numberOfSeats: numberOfSeats,
          numberOfSeatsChildren: numberOfSeatsChildren,
          firstName: userData.firstName || prevFormObject.firstName,
          lastName: userData.lastName || prevFormObject.lastName,
          countryCode: userData.countryCode || prevFormObject.countryCode,
          phoneNumber: userData.phoneNumber || prevFormObject.phoneNumber,
          email: userData.email || prevFormObject.email,
          date: prevFormObject.date,
          time: prevFormObject.time,
          note: prevFormObject.note
        };
        if (
          JSON.stringify(updatedFormObject) === JSON.stringify(prevFormObject)
        ) {
          return prevFormObject;
        }
        return updatedFormObject;
      });
    }
  }, [userData, countryCode, numberOfSeats, numberOfSeatsChildren]);

  useEffect(() => {}, [showProducts]);

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      console.log("no errors");
      setLoading(true);
      const data = {
        ...formObject,
        branchId: id,
        numberOfSeats,
        countryCode,
        numberOfSeatsChildren
      };
      console.log("data in isSubmit", data);
      reserveTable(`${globalConst.serverip}api/v1/public/reservetable`, data);
    }
  }, [formError]);

  async function getReserVePrice() {
    try {
      let response = await fetch(
        `${globalConst.serverip}api/v1/admin/branch/reservetableprice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong getting price");
      }

      if (response.status == 200) {
        let res = await response.json();
        setPrice(res.data.price);
      }
    } catch (error) {
      setPrice("0");
    }
  }

  useEffect(() => {
    // Extract userId from URL params
    const params = new URLSearchParams(window.location.search);
    const userId = params.get("userId");

    // Call action with slug and userId
    dispatch(getRestaurantInfo(slug, userId));

    // Additional logic if needed
    getReserVePrice();
  }, [slug]); // Add dependencies

  useEffect(() => {
    if (reserveTableId && price < 1) {
      payWithTidyPay();
    }
  }, [reserveTableId, price]);

  const reserveTable = async (url, data) => {
    try {
      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (res.data.status == 200) {
        setReserveTableId(res.data.data.id);
        setPrice(res.data.data.price);

        if (res.data.data.price > 0) {
          setIsVisible(true);
        }

        setLoading(false);
      } else {
        setIsVisible(false);
        setLoading(false);
        openNotification("Error", res.data.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateForm = (values) => {
    const errors = {};

    if (values.firstName === "") {
      errors.firstName = "*Please enter first name";
    } else if (!validName.test(values.firstName)) {
      errors.firstName = "*Please enter a valid first name";
    }

    if (values.lastName === "") {
      errors.lastName = "*Please enter last name";
    } else if (!validName.test(values.lastName)) {
      errors.lastName = "*Please enter a valid last name";
    }

    if (values.phoneNumber === "") {
      errors.phoneNumber = "*Please enter your phone number";
    } else if (!validatePhone.test(values.phoneNumber)) {
      errors.phoneNumber = "*Please enter a valid phone number";
    }

    if (values.email === "") {
      errors.email = "*Please enter email address";
    } else if (!validEmail.test(values.email)) {
      errors.email = "*Please provide a valid email address";
    }

    if (values.date === "") {
      errors.date = "*Please enter  date";
    } else {
      const today = new Date();
      var d1 = new Date(moment().subtract(1, "days"));
      var d2 = new Date(values.date);
      if (d1.getTime() > d2.getTime()) {
        errors.date = "*Please enter  a valid date";
      }
    }

    if (values.time === "") {
      errors.time = "*Please select time";
    }
    return errors;
  };

  let branchId = localStorage.getItem("branchId");

  if (branchId) {
    branchId = branchId.replace(/"/g, ""); // Removes extra quotes if any
  } else {
    console.log("branchId not found in localStorage.");
  }

  const getRestaurantProducts = (e) => {
    e.preventDefault();

    ///Form validation
    setFormError(validateForm(formObject));

    // Check if any form errors exist
    if (Object.keys(formError).length === 0) {
      // Check if any required field is empty
      if (
        formObject.firstName === "" ||
        formObject.lastName === "" ||
        formObject.phoneNumber === "" ||
        formObject.email === "" ||
        formObject.date === "" ||
        formObject.time === ""
      ) {
        alert("Please fill in all required fields.");
        return;
      }

      const data = {
        ...formObject,
        firstName: formObject.firstName,
        lastName: formObject.lastName,
        phoneNumber: formObject.phoneNumber,
        email: formObject.email,
        date: formObject.date,
        time: formObject.time,
        note: formObject.note,
        numberOfSeats: numberOfSeats,
        countryCode: countryCode,
        numberOfSeatsChildren: numberOfSeatsChildren,
        branchId: id
      };
      console.log("data in 1st page", formObject);

      setData(data);
    } else {
      // If form has validation errors
      alert("Please fill in all required fields correctly.");
      return;
    }

    if (!branchId) {
      alert("Branch ID is missing.");
      return;
    }

    setLoading(true);

    axios
      .post(`${globalConst.serverip}api/v1/public/reserveTableProducts`, {
        branchId: branchId,
        type: "id"
      })
      .then((res) => {
        setNewData(res.data.data);
        setShowProducts(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error:", err);
        setLoading(false);
      });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setFormError(validateForm(formObject));
    setIsSubmit(true);
    console.log("formObject", formObject);
  };

  // // const handleInputChange = (e) => {
  // //   const { name, value } = e.target;
  // //   setFormObject((prevFormObject) => ({
  // //     ...prevFormObject,
  // //     [name]: value
  // //   }));
  // // };
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   // Check if the selected time is within operating hours
  //   if (value < openingTime || value > closingTime) {
  //     alert(
  //       `The restaurant is closed at this time. Please select a time within operating hours. Opening Time: ${openingTime} Closing Time: ${closingTime}`
  //       // "The restaurant is closed at this time. Please select a time within operating hours."
  //     );
  //     return; // Prevent updating the form state if the time is invalid
  //   }

  //   setFormObject((prevFormObject) => ({
  //     ...prevFormObject,
  //     [name]: value
  //   }));
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the selected time is within operating hours
    if (name === "time" && (value < openingTime || value > closingTime)) {
      alert(
        `The restaurant is closed at this time. Please select a time within operating hours. Opening Time: ${openingTime} Closing Time: ${closingTime}`
      );
      return; // Prevent updating the form state if the time is invalid
    }

    setFormObject((prevFormObject) => ({
      ...prevFormObject,
      [name]: value
    }));
  };

  const payWithTidyPay = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${globalConst.serverip}api/v1/public/processpayment`,
        { id: reserveTableId },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      window.open(response.data.url, "_self");
      setLoading(false);
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };

  const checkAvailSeats = (type) => {
    if (type == "adult") {
      numberOfSeats + numberOfSeatsChildren < availableSeats
        ? setNumberOfSeats(numberOfSeats + 1)
        : openNotification(
            "Sorry",
            `Currently Only ${availableSeats}  seats are available`
          );
    } else {
      numberOfSeats + numberOfSeatsChildren < availableSeats
        ? setNumberOfSeatsChildren(numberOfSeatsChildren + 1)
        : openNotification(
            "Sorry",
            `Currently  Only ${availableSeats}  Seats are available`
          );
    }
  };

  // Map days to their respective opening and closing times
  const currentDayIndex = new Date().getDay();
  const operatingDays = {
    0: sunday,
    1: monday,
    2: tuesday,
    3: wednesday,
    4: thursday,
    5: friday,
    6: saturday
  };

  // Get today's operating hours using bracket notation to access nested openingTime and closingTime
  const todayHours = operatingDays[currentDayIndex] || {};
  const openingTime = todayHours.openingTime || "00:00";
  const closingTime = todayHours.closingTime || "23:59";
  // Get the current time
  const currentTime = new Date().toTimeString().split(" ")[0];

  // Check if the restaurant is closed
  const isClosed = currentTime < openingTime || currentTime > closingTime;
  const onCountryCodeSelectHandler = (e) => {
    let code = e.target.value.split(":")[1];
    setcountryCode(code);
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center loading ">
          <Spinner
            animation="border"
            variant="light"
            className="d-flex justify-content-center  align-items-center"
            style={{
              height: "120px",
              width: "120px",
              borderStyle: "solid",
              borderWidth: "8px"
            }}
          />
        </div>
      ) : (
        <>
          {!showProducts && (
            <Container
              fluid
              className="d-flex justify-content-center align-items-center main-container overflow-hidden"
            >
              {(err_status == 404 ||
                (additional && additional.reserveTablePermission == false)) && (
                <Row className="d-flex justify-content-between">
                  <div className="w-100 d-flex justify-content-center align-items-center pb-3">
                    <img src={NotFoundGif} alt="Page Not Found" width="600px" />
                  </div>
                </Row>
              )}

              {err_status != 404 &&
                additional &&
                additional.reserveTablePermission && (
                  <Row
                    className="d-flex flex-column align-items-center bg-white p-5"
                    id="reserveTable"
                  >
                    <div className="text-center reserveHead">
                      {isMobile && (
                        <>
                          <Row className="justify-content-between">
                            <Col span={6}>
                              <img
                                src={`${globalConst.s3url}${logo}`}
                                alt="Restaurant Logo"
                                height="150px"
                              />
                            </Col>
                            <Col
                              span={16}
                              className="d-flex flex-column justify-content-center text-left"
                            >
                              <h5>{name}</h5>
                              <h2 className="mb-0">RESERVER BORD</h2>
                              <h5>
                                {availableSeats
                                  ? `Total Available Seats:${availableSeats}`
                                  : ""}
                              </h5>
                            </Col>
                          </Row>
                          <Row className="justify-content-between">
                            <Col
                              span={24}
                              className="boxShadow d-flex p-2 m-1 justify-content-between"
                            >
                              <h6 className="mb-0">Voksen</h6>
                              <div style={{ fontSize: "16px" }}>
                                <button
                                  className="px-3"
                                  onClick={() =>
                                    numberOfSeats > 1 &&
                                    setNumberOfSeats(numberOfSeats - 1)
                                  }
                                >
                                  -
                                </button>{" "}
                                &nbsp; {numberOfSeats} &nbsp;
                                <button
                                  className="px-3"
                                  onClick={() =>
                                    availableSeats
                                      ? checkAvailSeats("adult")
                                      : setNumberOfSeats(numberOfSeats + 1)
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </Col>
                            <Col
                              span={24}
                              className="boxShadow d-flex p-2 m-1 justify-content-between"
                            >
                              <h6 className="mb-0">Barn</h6>
                              <div style={{ fontSize: "16px" }}>
                                <button
                                  className="px-3"
                                  onClick={() =>
                                    numberOfSeatsChildren > 0 &&
                                    setNumberOfSeatsChildren(
                                      numberOfSeatsChildren - 1
                                    )
                                  }
                                >
                                  -
                                </button>{" "}
                                &nbsp; {numberOfSeatsChildren} &nbsp;
                                <button
                                  className="px-3"
                                  onClick={() =>
                                    availableSeats
                                      ? checkAvailSeats("child")
                                      : setNumberOfSeatsChildren(
                                          numberOfSeatsChildren + 1
                                        )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      {!isMobile && (
                        <>
                          <Row className="justify-content-between">
                            <Col span={6}>
                              <img
                                src={`${globalConst.s3url}${logo}`}
                                alt="Restaurant Logo"
                                height="150px"
                              />
                            </Col>
                            <Col
                              span={16}
                              className="d-flex flex-column justify-content-center text-left"
                            >
                              <h5>{name}</h5>
                              <h2 className="mb-0">RESERVER BORD</h2>
                              <h5>
                                {availableSeats
                                  ? `Total Available Seats:${availableSeats}`
                                  : ""}
                              </h5>
                              <div>
                                <Row className="justify-content-between mb-4">
                                  <Col
                                    span={11}
                                    className=" d-flex p-2 justify-content-between boxShadow"
                                  >
                                    <h6 className="mb-0">Voksen</h6>
                                    <div style={{ fontSize: "16px" }}>
                                      <button
                                        className="px-2 btnShadow"
                                        onClick={() =>
                                          numberOfSeats > 1 &&
                                          setNumberOfSeats(numberOfSeats - 1)
                                        }
                                      >
                                        -
                                      </button>{" "}
                                      &nbsp; {numberOfSeats} &nbsp;
                                      <button
                                        className="px-2 btnShadow"
                                        onClick={() =>
                                          availableSeats
                                            ? checkAvailSeats("adult")
                                            : setNumberOfSeats(
                                                numberOfSeats + 1
                                              )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </Col>
                                  <Col
                                    span={11}
                                    className=" d-flex p-2 justify-content-between boxShadow"
                                  >
                                    <h6 className="mb-0">Barn</h6>
                                    <div style={{ fontSize: "16px" }}>
                                      <button
                                        className="px-2 btnShadow"
                                        onClick={() =>
                                          numberOfSeatsChildren > 0 &&
                                          setNumberOfSeatsChildren(
                                            numberOfSeatsChildren - 1
                                          )
                                        }
                                      >
                                        -
                                      </button>{" "}
                                      &nbsp; {numberOfSeatsChildren} &nbsp;
                                      <button
                                        className="px-2 btnShadow"
                                        onClick={() =>
                                          availableSeats
                                            ? checkAvailSeats("child")
                                            : setNumberOfSeatsChildren(
                                                numberOfSeatsChildren + 1
                                              )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}

                      <div style={{ width: "100%" }}>
                        <div className="main-row d-flex justify-content-between pb-1 w-100">
                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="FIRSTNAME">
                              Fornavn <span className="text-danger">*</span>{" "}
                            </Label>
                            <Input
                              className={formError.firstName && "reserveInput"}
                              type="text"
                              name="firstName"
                              id="FIRSTNAME"
                              value={
                                userData
                                  ? userData.firstName
                                  : formObject.firstName
                              }
                              onChange={handleInputChange}
                            />
                            {formError.firstName && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.firstName}
                              </p>
                            )}
                          </FormGroup>

                          <FormGroup className=" mb-sm-0 d-flex justify-content-between">
                            <Label for="LASTNAME">
                              Etternavn <span className="text-danger">*</span>
                            </Label>
                            <Input
                              className={formError.lastName && "reserveInput"}
                              type="text"
                              name="lastName"
                              id="LASTNAME"
                              placeholder=""
                              // value={formObject.lastName}
                              value={
                                userData
                                  ? userData.lastName
                                  : formObject.lastName
                              }
                              onChange={handleInputChange}
                            />
                            {formError.lastName && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.lastName}
                              </p>
                            )}
                          </FormGroup>
                        </div>

                        <div className="main-row d-flex justify-content-between pb-1 pt-1 w-100">
                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="=Number">
                              Telefon <span className="text-danger">*</span>
                            </Label>
                            <div className="d-flex">
                              <Input
                                type="select"
                                name="select"
                                id="selectField"
                                onChange={onCountryCodeSelectHandler}
                                defaultValue={"[NO]:47"} // Set default to Norway
                                // disabled={!!formObject.countryCode} // Disable the input if user data exists
                              >
                                {filteredCountryCodes.map((cn) => {
                                  return (
                                    <option key={cn} value={cn}>
                                      {cn.split(":")[1]}{" "}
                                      {/* This will show the country calling code */}
                                    </option>
                                  );
                                })}
                              </Input>

                              <Input
                                className={`form-control ${
                                  !!userData ? "non-editable" : ""
                                } ${
                                  formError.phoneNumber ? "reserveInput" : ""
                                }`}
                                type="number"
                                name="phoneNumber"
                                id="PHONENUMBER"
                                placeholder=""
                                value={
                                  userData
                                    ? userData.phoneNumber
                                    : formObject.phoneNumber
                                }
                                onInput={(e) => {
                                  if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    );
                                  }
                                  handleInputChange(e);
                                }}
                                onChange={handleInputChange}
                                // disabled={!!formObject.phoneNumber} // Disable the input if user data exists
                              />
                            </div>
                            {formError.phoneNumber && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.phoneNumber}
                              </p>
                            )}
                          </FormGroup>

                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="emailId">
                              E-Post <span className="text-danger">*</span>
                            </Label>
                            <Input
                              className={`form-control ${
                                formError.email ? "reserveInput" : ""
                              }`}
                              type="text"
                              name="email"
                              id="emailId"
                              value={formObject.email}
                              onChange={handleInputChange}
                              // disabled={!!formObject.email} // Disable the input if user data exists
                              // readOnly={!!formObject.email} // Make the input read-only if user data exists
                            />
                            {formError.email && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.email}
                              </p>
                            )}
                          </FormGroup>
                        </div>

                        <div className="main-row d-flex justify-content-between pb-1 pt-1 w-100">
                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="exampleDate">
                              <span className="text-danger">*</span>
                            </Label>
                            {/* <DatePicker
                              showTime={{ format: "HH:mm" }} // Show time in hours and minutes format
                              format="YYYY-MM-DD HH:mm"
                              value={
                                dateTime
                                  ? moment(dateTime, "YYYY-MM-DD HH:mm")
                                  : moment()
                              } // Display selected date and time or current date and time
                              onChange={onDateTimeChange}
                              disabledDate={disabledDate} // Disable past dates
                              disabledTime={disabledTime} // Restrict time to opening and closing hours
                              className={
                                formError.dateTime ? "reserveInput" : ""
                              } // Add error class if needed */}
                            {/* /> */}

                            <Input
                              className={formError.date && "reserveInput"}
                              type="date"
                              name="date"
                              id="exampleDate"
                              placeholder=""
                              value={formObject.date || ""} // Initially empty to allow the user to interact
                              min={currentDate} // Prevent selecting a date before today
                              onChange={(e) => {
                                // Ensure the date is updated in the form object
                                handleInputChange(e);
                              }}
                              onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                            />
                            {formError.date && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.date}
                              </p>
                            )}
                          </FormGroup>

                          {/* <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="exampleDate">
                              Dato <span className="text-danger">*</span>
                            </Label>
                            <Input
                              className={formError.date && "reserveInput"}
                              type="date"
                              name="date"
                              id="exampleDate"
                              placeholder=""
                              value={formObject.date}
                              onChange={handleInputChange}
                              onKeyDown={(e) => e.preventDefault()} // Prevent typing manually
                            />
                            {formError.date && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.date}
                              </p>
                            )}
                          </FormGroup> */}

                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="exampleTime">
                              Tid <span className="text-danger">*</span>
                            </Label>
                            <TimePicker
                              className={formError.time ? "reserveInput" : ""}
                              name="time"
                              id="exampleTime"
                              value={
                                formObject.time
                                  ? moment(formObject.time, "HH:mm")
                                  : null
                              } // Use moment to format the time
                              onChange={(time, timeString) =>
                                handleInputChange({
                                  target: { name: "time", value: timeString }
                                })
                              } // Handle time change
                              disabled={isClosed} // Disable if the restaurant is closed
                              format="HH:mm" // Set the format
                              showNow={false} // Hide the 'now' button
                              hideDisabledOptions // Optionally hide disabled options
                            />
                            {isClosed && <p>Restaurant is closed</p>}
                            {formError.time && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.time}
                              </p>
                            )}
                          </FormGroup>
                        </div>

                        <div className="main-row d-flex pb-1 pt-1 w-100">
                          <FormGroup className="mb-sm-0 w-100 d-flex justify-content-between">
                            <Label for="Note">Merk</Label>
                            <TextArea
                              type="textarea"
                              name="note"
                              id="Note"
                              autoSize={{ minRows: 3, maxRows: 4 }}
                              maxLength="500"
                              value={formObject.note}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <Row className="w-100 justify-content-between mt-2">
                        <Col span={11}>
                          <Button
                            type="submit"
                            className="sendBooking"
                            onClick={submitForm}
                          >
                            Send Bestilling
                          </Button>
                        </Col>
                        <Col span={11}>
                          <Button
                            type="submit"
                            className="sendBooking"
                            onClick={getRestaurantProducts}
                          >
                            Reserver mat
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                )}

              {isVisible && (
                <Modal isOpen={isVisible}>
                  <ModalHeader
                    toggle={() => {
                      setIsVisible(false);
                    }}
                  >
                    Bekreft bestillingen
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Din reservasjons sum blir konvertert til et gavekort som
                      du mottar kort tid etter betalingen er fullført.
                    </p>
                    <h4 className="text-center">{`Betal ${price} kr`}</h4>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-center align-items-center">
                    <Button color="primary" onClick={payWithTidyPay}>
                      Pay
                    </Button>
                  </ModalFooter>
                </Modal>
              )}
            </Container>
          )}

          {showProducts && (
            <GetAllData alldata={newData} data={data} showProducts={true} />
          )}
        </>
      )}
    </>
  );
};

export default ReserveTable;
