import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Button, Modal, Collapse, Descriptions } from "antd";
import { getTaxModule } from "../helpers/taxmodule";
import offer from "../assets/offer.png";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { s3url, serverip } from "../helpers/global";
import { useHistory } from "react-router-dom";
// import s3url from "../helpers/global";

export default function GetAllData(props, show) {
  let data = props.data;
  console.log("data", data);
  console.log("komal is checking showProducts", show);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [cartProducts, setCartProducts] = useState([]);
  const [totalOfProducts, setTotalOfProducts] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isPaymentModalOpened, setPaymentIsModalOpened] = useState(false);
  const [newCartQuantity, setNewCartQuantity] = useState(0);
  const [totalWithExtras, setTotalWithExtras] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemToDelete, setItemToDelete] = useState();
  const [offerEnable, setOfferEnable] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [tax, setTax] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { Panel } = Collapse;

  const restaurant = useSelector((state) => {
    return state.restaurant;
  });
  console.log("res", restaurant.slug);
  useEffect(() => {
    if (props.alldata.length > 0) {
      setSelectedItemIndex(0);
      setSelectedCategory(props.alldata[0]);
    }
  }, [props.alldata]);

  useEffect(() => {}, [isModalOpened]);

  useEffect(() => {
    setTaxValue((100 + Number(tax)) / 100);
  }, [tax]);

  useEffect(() => {
    TotalAmountOfProducts();
  }, [cartProducts]);

  useEffect(() => {
    setSelectedItems([]);
  }, [cartProducts]);

  useEffect(() => {
    const myTax = getTaxModule(restaurant.taxes, "table");
    setTax(myTax);
  }, []);
  const RemoveChecks = () => {
    const allWithClass = Array.from(document.getElementsByClassName("mycheck"));
    allWithClass.map((ele) => {
      ele.checked = false;
    });
  };
  const showModal = (prod) => {
    setItemToDelete(prod);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteSingleItem(itemToDelete);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModaled = (prod) => {
    RemoveChecks();
    setSelectedProduct(prod);
    setIsModalOpened(true);
  };
  const filterItemsCategory = (img, index) => {
    setSelectedImg(img);
  };

  const handledOk = () => {
    setIsModalOpened(false);
  };

  // const handleCanceled = (selected) => {
  //   let itemsDeleted = selectedItems.filter(
  //     (item) => item._id !== selected._id
  //   );
  //   const newTotal = itemsDeleted.reduce(
  //     (total, item) => total + Number(item.price),
  //     0
  //   );
  //   setTotalWithExtras(newTotal);
  //   RemoveChecks();
  //   setIsModalOpened(false);
  // };

  const filterItems = (product, index) => {
    setSelectedCategory(product);
    setSelectedItemIndex(index);
  };

  const clearCart = () => {
    setCartProducts([]);
    setOfferPrice([]);
  };

  const TotalAmountOfProducts = () => {
    const totalQuantity = cartProducts.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    setNewCartQuantity(totalQuantity);
    console.log("Total quantity of products", totalQuantity);
    const productTotal = cartProducts.reduce((oldValue, newValue) => {
      let price = newValue.price;
      let todayDate = new Date();
      if (productOfferEnabled(newValue)) {
        price = newValue.offer.offerPrice;
      }
      return (oldValue += newValue.quantity * price);
    }, 0);
    setTotalOfProducts(productTotal);
  };

  const deleteSingleItem = (item) => {
    let itemDeleted = cartProducts.filter((e) => e._id !== item._id);
    setCartProducts(itemDeleted);
  };
  const productNames = cartProducts.map((product) => ({
    id: product._id,
    name: product.name,
    quantity: product.quantity
  }));

  const requestData = {
    ...data,
    cartProducts: productNames,
    itemsNames: itemsNames,
    isWebUser: false,
    orderFrom: "WEB"
  };
  const [response, setResponse] = useState(null);

  const proceedPayment = async () => {
    let branchId = localStorage.getItem("branchId");
    try {
      const response = await axios.post(
        `${serverip}api/v1/public/reservetable`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (response.status === 200) {
        console.log("response", response);
        const paymentUrl = response.data.data.url; // Extract the URL from the response
        setResponse(requestData); // Store the requestData or any necessary response data
        // Redirect the user to the payment URL
        window.location.href = paymentUrl;
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // Fix for itemsNames
  const itemsNames = cartProducts.map((product) => product.name).join(", ");

  const addToCart = (prod, productAction) => {
    if (!prod) {
      console.error("Product is null or undefined");
      return;
    }

    let d = cartProducts.filter((e) => e._id == prod._id);
    if (d.length > 0) {
      let updatedItem = cartProducts.map((obj) => {
        if (obj._id == prod._id) {
          let nQuantity = obj.quantity;
          let prodPrice = prod.price;

          if (productAction == "add") {
            nQuantity += 1;
          }

          if (productAction == "minus") {
            if (obj.quantity < 2) {
              showModal(prod);
            } else {
              nQuantity -= 1;
            }
          }

          if (productOfferEnabled(prod)) {
            prodPrice = prod.offer.offerPrice;
          }

          let ntotalProductPrice = nQuantity * prodPrice;
          return {
            ...obj,
            quantity: nQuantity,
            totalProductPrice: ntotalProductPrice
          };
        }

        return obj;
      });

      setCartProducts(updatedItem);
    } else {
      let this_prod = prod;
      let todayDate = new Date();

      let prodPrice = prod.price;

      if (productOfferEnabled(prod)) {
        prodPrice = prod.offer.offerPrice;
      }

      this_prod.quantity = 1;
      this_prod.totalProductPrice = this_prod.quantity * prodPrice;

      setCartProducts((old) => [...old, this_prod]);
      setOfferEnable(prod.offerEnable);
      setOfferPrice(prod.offer.offerPrice);
      setStartDate(prod.offer.startDate);
      setEndDate(prod.offer.endDate);
    }
  };

  const productOfferEnabled = (prod) => {
    let todayDate = new Date();
    if (prod.offerEnable) {
      let offerStartDate = new Date(prod.offer.startDate);
      let offerEndDate = new Date(prod.offer.endDate);

      if (todayDate >= offerStartDate && todayDate <= offerEndDate) {
        return true;
      }
    }
    return false;
  };

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);

  const toggleProductModal = () => {
    setIsProductModalOpen(!isProductModalOpen);
  };

  const toggleCartModal = (data) => {
    setIsCartModalOpen(!isCartModalOpen);
    console.log("isCartModalOpen", data);
  };
  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleProductClick = (product) => {
    console.log("here i am clicked in handleProductClick");
    setSelectedProduct(product);
    toggleProductModal();
  };
  const { showProducts = false, onShowProductsChange } = props;
  const [localShowProducts, setLocalShowProducts] = useState(showProducts);

  const handleBackButtonClick = () => {
    setLocalShowProducts(false);
    if (onShowProductsChange) {
      onShowProductsChange(false); // Update the parent state
      console.log("showProducts", showProducts);
    }
  };

  useEffect(() => {
    if (localShowProducts == false) {
      console.log("hey there i am in if", props);
    } else {
      console.log("hey there");
    }
  }, [localShowProducts]);

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center loading ">
          <Spinner
            animation="border"
            variant="light"
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "120px",
              width: "120px",
              borderStyle: "solid",
              borderWidth: "8px"
            }}
          />
        </div>
      ) : (
        <>
          {!isMobile && (
            <Row className="p-5 d-flex justify-content-between reserveTableProductPage w-100">
              <Col span={6} className="mainCategory bg-white rounded">
                <div className="box">
                  {props.alldata.map((item, index) => (
                    <a
                      key={item._id} // Ensure _id is unique and stable
                      onClick={() => filterItems(item, index)}
                      className={`d-flex p-3 productItem ${
                        selectedItemIndex === index ? "productitem" : ""
                      }`}
                    >
                      <h4>{item.name}</h4>{" "}
                    </a>
                  ))}
                </div>
              </Col>
              <Col
                span={8}
                className="bg-whit middle-box rounded"
                // style={{ overflowY: "scroll" }}
              >
                <div className="box">
                  {selectedCategory &&
                    selectedCategory.products.map((product, index) => (
                      <Row
                        key={index}
                        className="d-flex justify-content-between Category-Image"
                      >
                        {productOfferEnabled(product) ? (
                          <Col span={20} className="d-flex">
                            {/* {globalConst &&
                              globalConst.s3url &&
                              product &&
                              product.image && (
                                <img
                                  src={`${s3url}${product.image}`}
                                  alt="Category Image"
                                  width="100px"
                                  className="p-2"
                                />
                              )} */}
                            <img
                              src={`${s3url}${product.image}`}
                              alt="Category Image"
                              width="100px"
                              className="p-2"
                            />
                            <div className="d-flex flex-column justify-content-center">
                              <h4>{product.name}</h4>
                              <div className="d-flex">
                                <h5
                                  style={{ fontWeight: "bold" }}
                                  className="mb-0"
                                >
                                  Kr.{}
                                </h5>
                                &nbsp;&nbsp;
                                <h5
                                  style={{ textDecorationLine: "line-through" }}
                                  className="mb-0"
                                >
                                  Kr.{product.price}
                                </h5>
                                {productOfferEnabled(product) && (
                                  <img
                                    src={offer}
                                    alt="offer available offerimg"
                                    width={70}
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <Col span={20} className="d-flex align-items-center">
                            {/* <img
                              src={`${globalConst.s3url}${selectedCategory.image}`}
                              alt="Category Image"
                              width="20%"
                              className="p-2"
                            /> */}
                            {s3url &&
                              selectedCategory &&
                              selectedCategory.image && (
                                <img
                                  src={`${s3url}${selectedCategory.image}`}
                                  alt="Category Image"
                                  width="100px"
                                  className="p-2"
                                />
                              )}
                            <div className="d-flex flex-column">
                              <h4>{product.name}</h4>
                              <h5
                                style={{
                                  fontWeight: "bold"
                                }}
                              >
                                Kr.{product.price}
                              </h5>
                            </div>
                          </Col>
                        )}
                        <Col
                          span={4}
                          className="d-flex"
                          onClick={() => filterItemsCategory(product, index)}
                        >
                          <button
                            className="addToCartbtn w-100 h-100"
                            onClick={() => addToCart(product, "add")}
                          >
                            Add
                          </button>
                        </Col>
                      </Row>
                    ))}
                </div>
              </Col>
              <Col span={9} className="bg-white rounded-lg">
                <Row className="h-100 w-100 justify-content-between">
                  <Col
                    span={24}
                    className="w-100 p-4"
                    style={{ height: "60vh", overflowY: "scroll" }}
                  >
                    {selectedImg && (
                      <div className="menu-images d-flex flex-column align-items-start justify-content-center">
                        {cartProducts.map((product) => (
                          <Row className="cartBox reserveCart mb-3">
                            <Col key={product._id} span={16} className="pr-3">
                              <div>
                                <h5>{product.name}&nbsp;</h5>
                              </div>
                              <a
                                onClick={() => showModal(product)}
                                className="closeicon text-bold"
                              >
                                <u style={{ color: "red" }}>Delete</u>
                              </a>
                            </Col>
                            <Col
                              span={4}
                              className="d-flex justify-content-between"
                            >
                              {product.quantity > 1 ? (
                                <button
                                  className="minus-btn dec"
                                  onClick={() => addToCart(product, "minus")}
                                >
                                  -
                                </button>
                              ) : (
                                <>&nbsp;&nbsp;</>
                              )}
                              <p className="mb-0 mx-2 d-flex justify-content-center align-items-center">
                                {product.quantity}
                              </p>
                              <button
                                className="inc"
                                onClick={() => addToCart(product, "add")}
                              >
                                +
                              </button>
                            </Col>
                            <Col
                              span={4}
                              className="d-flex justify-content-end align-items-center"
                            >
                              <h4>Kr.{product.totalProductPrice}</h4>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                  </Col>
                  <Col span={24} className="text-right">
                    <Descriptions title="Payment Break Out" bordered>
                      <Descriptions.Item label="Sub-Total:">
                        Kr.
                        {offerEnable
                          ? totalOfProducts &&
                            (totalOfProducts + totalWithExtras) / taxValue
                          : totalOfProducts &&
                            (totalOfProducts + totalWithExtras) / taxValue}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered>
                      <Descriptions.Item label={`Tax (${tax}%):`}>
                        Kr.{" "}
                        {(offerEnable
                          ? totalOfProducts -
                            totalOfProducts / taxValue +
                            totalWithExtras -
                            totalWithExtras / taxValue
                          : totalOfProducts -
                            totalOfProducts / taxValue +
                            totalWithExtras -
                            totalWithExtras / taxValue
                        ).toFixed(2)}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered>
                      <Descriptions.Item label="Total Amount">
                        Kr.
                        {totalWithExtras + totalOfProducts}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={24} className="d-flex rounded-bottom">
                    <button
                      onClick={(e) => clearCart(e)}
                      className="clearcart-btn w-100 rounded-bottom"
                      style={{
                        backgroundColor: "red",
                        border: "0",
                        color: "white"
                      }}
                    >
                      Clear Cart
                    </button>
                    <button
                      onClick={() => proceedPayment()}
                      className="clearcart-btn w-100 rounded-bottom"
                      style={{
                        backgroundColor: "#13cd52",
                        border: "0",
                        color: "white"
                      }}
                    >
                      Proceed Payment
                    </button>
                  </Col>
                </Row>

                {cartProducts.filter((product) => product.quantity < 1) && (
                  <Modal
                    visible={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <p className="px-3 pt-1" style={{ fontWeight: "bold" }}>
                      You have reached the maximum limit.
                    </p>
                    <p className="px-3 pt-1">
                      Are you sure to remove this item.
                    </p>
                  </Modal>
                )}
              </Col>
            </Row>
          )}
          {isMobile && (
            <div className="h-100 w-100">
              <Row className="reserveTableOrdering w-100 bg-white p-0">
                <Col span={12}>
                  <button onClick={handleBackButtonClick}>Back</button>
                </Col>
                <Col span={12}>
                  <Button
                    className="w-100 cartButton"
                    onClick={toggleCartModal}
                    style={{ backgroundColor: "#13cd52", color: "white" }}
                  >
                    View Cart ({newCartQuantity})
                    {/* Display the number of items in the cart */}
                  </Button>
                </Col>
              </Row>
              <Row
                className="d-flex justify-content-between"
                style={{ height: "90vh" }}
              >
                <div
                  className="w-100"
                  style={{
                    paddingBottom: "70px",
                    height: "90vh",
                    overflow: "hidden",
                    overflowY: "scroll"
                  }}
                >
                  <Col className="CollapseCategoryMobile">
                    {props.alldata && props.alldata.length > 0 ? (
                      <Collapse
                        accordion
                        bordered={false}
                        defaultActiveKey={["1"]}
                        className="site-collapse-custom-collapse w-100"
                      >
                        {props.alldata.map((subCategory, index) => (
                          <Panel
                            header={subCategory.name}
                            key={index + 1}
                            className="site-collapse-custom-panel"
                          >
                            <Col xs lg="3" className="subCat p-3">
                              {subCategory.products.map(
                                (product, productIndex) => (
                                  <Row
                                    className="suCatProduct justify-content-between"
                                    key={productIndex}
                                    onClick={() => handleProductClick(product)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Col span={4}>
                                      <img
                                        src={`${s3url}${product.image}`}
                                        alt="Category Image"
                                        width="100%"
                                        className="p-2"
                                      />
                                    </Col>
                                    <Col span={17}>
                                      {product.name}
                                      <br />
                                      Kr. {product.price}
                                    </Col>
                                  </Row>
                                )
                              )}
                            </Col>
                          </Panel>
                        ))}
                      </Collapse>
                    ) : (
                      <div>No subcategories available</div>
                    )}
                  </Col>
                </div>
                <Modal
                  className="reserveProductModal"
                  title="Product Details"
                  visible={isProductModalOpen}
                  onCancel={toggleProductModal}
                  footer={null} // Customize the footer if needed
                >
                  {selectedProduct && (
                    <div className="prodAdd">
                      <img
                        src={`${s3url}${selectedProduct.image}`}
                        alt="Category Image"
                        width="100%"
                        className="p-2"
                      />
                      <h4>{selectedProduct.name}</h4>
                      <p>
                        Price: Kr.
                        {selectedProduct.offer
                          ? selectedProduct.offer.offerPrice
                          : selectedProduct.price}
                      </p>
                      {/* Add more product details here */}
                      <div className="d-flex justify-content-around">
                        <Button
                          className="w-100"
                          style={{ background: "#28a745", color: "#fff" }}
                          onClick={() => addToCart(selectedProduct, "add")}
                        >
                          Add to Cart
                        </Button>{" "}
                        {/* <Button onClick={toggleProductModal}>Cancel</Button> */}
                      </div>
                    </div>
                  )}
                </Modal>

                <Modal
                  title="Cart Details"
                  className="reserveTableCart"
                  visible={isCartModalOpen}
                  onCancel={toggleCartModal}
                  footer={[
                    <Row className="justify-content-between">
                      {!newCartQuantity <= 0 && (
                        <>
                          <Col span={11}>
                            <Button
                              className="w-100"
                              key="clear"
                              onClick={(e) => clearCart(e)}
                              type="danger"
                            >
                              Clear Cart
                            </Button>
                          </Col>

                          <Col span={11}>
                            <Button
                              className="w-100"
                              key="proceed"
                              onClick={proceedPayment}
                              type="primary"
                            >
                              Proceed to Payment
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  ]}
                >
                  {!newCartQuantity <= 0 ? (
                    <div className="menu-images d-flex flex-column align-items-start justify-content-center">
                      <div className="cartScroll">
                        {cartProducts.map((product) => (
                          <Row
                            className=" reserveCart mb-3 justify-content-between w-100"
                            key={product._id}
                          >
                            <Col span={16} className="pr-3">
                              <div>
                                <h5>{product.name}&nbsp;</h5>
                              </div>
                              <a
                                onClick={() => showModal(product)}
                                className="closeicon text-bold"
                              >
                                <u style={{ color: "red" }}>Delete</u>
                              </a>
                            </Col>
                            <Col span={6} className="d-flex flex-column">
                              <div className="d-flex justify-content-between text-center">
                                {product.quantity > 1 ? (
                                  <button
                                    className="minus-btn dec"
                                    onClick={() => addToCart(product, "minus")}
                                  >
                                    -
                                  </button>
                                ) : (
                                  <>&nbsp;&nbsp;</>
                                )}
                                <p className="mb-0 d-flex justify-content-center align-items-s">
                                  {product.quantity}
                                </p>
                                <button
                                  className="inc"
                                  onClick={() => addToCart(product, "add")}
                                >
                                  +
                                </button>
                              </div>
                              <h4 className="text-center">
                                Kr.{product.totalProductPrice}
                              </h4>
                            </Col>
                          </Row>
                        ))}
                      </div>
                      <Row>
                        <Descriptions title="Payment Break Out" bordered>
                          <Descriptions.Item label="Sub-Total:">
                            Kr.
                            {offerEnable
                              ? totalOfProducts &&
                                (totalOfProducts + totalWithExtras) / taxValue
                              : totalOfProducts &&
                                (totalOfProducts + totalWithExtras) / taxValue}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions bordered>
                          <Descriptions.Item label={`Tax (${tax}%):`}>
                            Kr.{" "}
                            {(offerEnable
                              ? totalOfProducts -
                                totalOfProducts / taxValue +
                                totalWithExtras -
                                totalWithExtras / taxValue
                              : totalOfProducts -
                                totalOfProducts / taxValue +
                                totalWithExtras -
                                totalWithExtras / taxValue
                            ).toFixed(2)}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions bordered>
                          <Descriptions.Item label="Total Amount">
                            Kr.
                            {totalWithExtras + totalOfProducts}
                          </Descriptions.Item>
                        </Descriptions>
                      </Row>
                    </div>
                  ) : (
                    <>Add item to the cart</>
                  )}
                </Modal>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
}
