import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import wentWrong from "../assets/error-gif.gif";
import queryString from "query-string";
import paySuccess from "../assets/success.gif";
import {
  clearStorage,
  order_transaction_check,
  table_order_transaction_check
} from "../actions";

class TableOrderConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: "",
      loading: false,
      paymentLoading: true,
      paymentType: "Tidy",
      queryString: ""
    };
  }
  componentDidMount() {
    var rr = this.useQuery();

    this.setState({
      orderId: rr,
      queryString: queryString.parse(this.props.location.search)
    });

    setTimeout(() => {
      if (rr) {
        this.setState({
          paymentLoading: false
        });

        this.orderCheck(rr);
        // this.props.dispatch(clearStorage());
      }
    }, 2000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.ordertransactionCheckSuccess ==
      this.props.ordertransactionCheckSuccess
    ) {
    }

    console.log(
      "hellooo",
      this.props.ordertransactionCheckData,
      this.props.ordertransactionCheckData.message
    );

    if (
      this.props.ordertransactionCheckData &&
      this.props.ordertransactionCheckData.message == "UNAUTHORIZED"
    ) {
      this.props.dispatch(clearStorage());
    } else {
    }
  }
  useQuery() {
    var quey = queryString.parse(this.props.location.search);
    // this.setState({ transaction_id: quey.transaction_id });
    //return quey.merchant_reference;

    let id = "";

    if (quey.merchant_reference) {
      id = quey.merchant_reference;
      this.setState({
        paymentType: "Dintero"
      });
    }

    if (quey.responseCode) {
      id = quey.transactionId;
    }

    return id;
  }

  orderCheck(id) {
    console.log("TidyPay response is: ", this.state.queryString);

    var postBody = {
      id: id,
      paymentType: this.state.paymentType,
      tidyPayResponse: this.state.queryString,
      paymentResponse: this.state.queryString
    };

    let { dispatch } = this.props;
    dispatch(table_order_transaction_check(postBody));
  }

  // const OTP = ({ otp, dispatch, history, slug }) => {

  clearState = () => {
    this.props.dispatch(clearStorage());
    // dispatch(deleteTable(slug))
    this.props.history.push(`/restaurant/booktable/${this.props.slug}`);
    // this.props.history.push(`/restaurant/booktable/${this.props.slug}`);
  };
  render() {
    return (
      <div className="success-payment d-flex flex-column justify-content-center align-items-center">
        {this.props.ordertransactionCheckLoading ||
        this.state.paymentLoading ? (
          <h3 align="center">Loading..</h3>
        ) : (
          <>
            {(this.props.ordertransactionCheckData &&
              this.props.ordertransactionCheckData.message == "UNAUTHORIZED") ||
            this.props.ordertransactionCheckError ? (
              <img src={wentWrong} alt="Error" width="150px" />
            ) : (
              <img src={paySuccess} width="250px" />
            )}

            {(this.props.ordertransactionCheckData &&
              this.props.ordertransactionCheckData.message == "UNAUTHORIZED") ||
            this.props.ordertransactionCheckError ? (
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                {/* <img src={paySuccess} alt="Success" width="250px" /> */}
                <h3 className="text-white">Payment Unsuccessful!</h3>
                <p className="text-white">
                  Something went wrong.
                  <br />
                  Your order is not placed.
                </p>
                <Button
                  // color="success"
                  outline
                  className="orderBtn text-white"
                  onClick={() => this.clearState()}
                >
                  Gå til nettsiden
                </Button>
                <Button
                  className="orderBtn text-white"
                  // color="success"
                  outline
                  onClick={() => {
                    this.props.history.push("/tableorder/cart");
                  }}
                >
                  Prøv å betale på nytt
                </Button>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                {/* <img src={paySuccess} alt="Success" width="250px" /> */}
                <h3 className="text-white">Payment Successful!</h3>
                <p className="text-white">
                  Thank you for the Payment. You'll get the confirmation SMS or
                  E-Mail from the Restaurant soon.
                </p>
                <Button
                  // color="success"
                  outline
                  className="orderBtn text-white"
                  onClick={() => this.clearState()}
                >
                  {/* Place New Order */}
                  Legg inn ny ordre
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ order, restaurant }) => {
  return {
    otp: order.otp,
    id: restaurant.id,
    slug: restaurant.slug,
    orderType: order.orderTypes,
    ordertransactionCheckLoading: order.ordertransactionCheckLoading,
    ordertransactionCheckSuccess: order.ordertransactionCheckSuccess,
    ordertransactionCheckError: order.ordertransactionCheckError,
    ordertransactionCheckData: order.ordertransactionCheckData
    // orderPaymentCheckLoading: order.orderPaymentCheckLoading,
    // orderPaymentCheckSuccess: order.orderPaymentCheckSuccess,
    // orderPaymentCheckData: order.orderPaymentCheckData
  };
};

export default withRouter(connect(mapStateToProps)(TableOrderConfirm));
