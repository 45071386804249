import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import globalConst from "../helpers/global";
import { message } from "antd";
import loadingGif from "../assets/loading-gif.gif";
import wentWrong from "../assets/error-gif.gif";
import paySuccess from "../assets/success.gif";

const reserveTableFallbackPage = (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const handleGoBack = () => {
    props.history.goBack(); // or use `navigate(-1)` in React Router v6
  };
  useEffect(async () => {
    try {
      console.log("id", id);
      const response = await axios.post(
        `${globalConst.serverip}api/v1/public/confirmResreveTablePaymentVipps`,
        { id: id },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.data.status == 200) {
        console.log("response", response);
        setLoading(false);
        console.log("response after payment confirm is", response);
      } else {
        setLoading(false);
        setIsError(true);
      }
    } catch (error) {
      setLoading(false);
      setIsError(true);
    }
  }, []);

  return loading ? (
    <div className="loading-gif">
      {/* <h3>Loading....</h3> */}
      <img src={loadingGif} alt="Loading" width="80px" />
    </div>
  ) : isError ? (
    <div className="error-page d-flex flex-column justify-content-center align-items-center">
      <img src={wentWrong} alt="Loading" width="150px" />
      <h3>Something went wrong</h3>
      <a
        className="orderBtn text-white px-5 py-2"
        style={{ background: "#12c751", borderRadius: 5 }}
        onClick={handleGoBack}
      >
        Return
      </a>
    </div>
  ) : (
    <div className="success-payment d-flex flex-column justify-content-center align-items-center">
      <img src={paySuccess} alt="Loading" width="250px" />
      <h3>Payment Successful!</h3>
      <p>
        Thankyou for the Payment. You'll get the confirmation SMS or E-Mail from
        the Restaurant soon.
      </p>
      <a
        className="orderBtn text-white px-5 py-2"
        style={{ background: "#12c751", borderRadius: 5 }}
        onClick={handleGoBack}
      >
        Return
      </a>
    </div>
  );
};

export default reserveTableFallbackPage;
