import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import { ListGroup, ListGroupItem, Button, Card } from "reactstrap";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { getTaxModule } from "../helpers/taxmodule";
import { useDispatch } from "react-redux";
import ModelComp from "./ModelComp";
import { useSelector } from "react-redux";
import vipps from "../assets/vipps.png";
const { Panel } = Collapse;
const Cart = ({
  cart,
  totalPrice,
  totalQuantity,
  openDeliveryOptions,
  removeItemFromCart,
  removeExtraFromCart,
  placeOrder,
  payment,
  location,
  active,
  button,
  isTableOrder,
  driveoutCharge,
  orderType,
  taxes
}) => {
  let [taxValue, setTaxValue] = useState(0);
  let [driveOC, setDriveOC] = useState(0);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isVippsPermission = useSelector(
    (state) => state.restaurant.additional.vippsPermission
  );

  const webpayment = useSelector((state) => state.restaurant.webpayment);
  const state1 = useSelector((state) => state);
  const status = useSelector(
    (state) => state.paymentConfirmReducer.data.status
  );

  useEffect(() => {
    let moduleOrderType = orderType;
    if (orderType == "table") {
      moduleOrderType = "table";
    } else if (orderType == "Drive Out") {
      moduleOrderType = "driveOut";
      let doc = getTaxModule(taxes, "driveOutCharge");
      let taxDoc = 100 + Number(doc);
      setDriveOC(taxDoc);
    } else {
      moduleOrderType = "onlineOrder";
    }
    if (taxes && moduleOrderType) {
      let tax = getTaxModule(taxes, moduleOrderType);
      let taxValue = (100 + Number(tax)) / 100;
      setTaxValue(taxValue);
    }
  });
  const paymentbuttonClicked = (val) => {
    setIsLoading(true);
    placeOrder(val);
  };
  const minusButtonclicked = (prodid, itemid, index) => {
    removeExtraFromCart(prodid, itemid, index);
  };
  console.log("komal is checking cart", cart);

  return (
    <>
      <ModelComp isTrue={isLoading} />
      <Card className="sidebar_element bg">
        <div className="total_product_element">
          <ul className="cardHover">
            <li>
              {button === "Continue" && (
                <Button
                  color="success"
                  disabled={cart.length < 1}
                  onClick={openDeliveryOptions}
                >
                  Fortsett
                </Button>
              )}
              {button === "Place Order" &&
                (location.pathname === "/checkout/pickup" ||
                  location.pathname === "/checkout/deliver") && (
                  <>
                    {webpayment !== "none" ? (
                      <Button
                        style={{ marginRight: "5px", marginLeft: "5px" }}
                        color="success"
                        className="cardBtn"
                        disabled={!active || cart.length < 1}
                        //onClick={placeOrder},
                        onClick={() => paymentbuttonClicked(false)}
                        // onClick={payment}
                      >
                        Pay
                        {/* Place Order */}
                      </Button>
                    ) : (
                      <></>
                    )}

                    {isVippsPermission && (
                      <Button
                        className="vippsCard"
                        color="warning"
                        disabled={!active || cart.length < 1}
                        onClick={() => paymentbuttonClicked(true)}
                        // onClick={payment}
                      >
                        {/* Pay With Vipps */}
                        {/* Place Order */}
                      </Button>
                    )}
                  </>
                )}
              {button === "tableOrder" && isTableOrder && (
                <Button
                  color="success"
                  disabled={!active || cart.length < 1}
                  onClick={placeOrder}
                  // onClick={payment}
                >
                  Bestill
                  {/* Place Order */}
                </Button>
              )}
              {(location.pathname === "/checkout/pickup/otp" ||
                location.search == "?vipps=true") && (
                <span
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "white",
                    marginTop: "10px"
                  }}
                >
                  Order Summary
                </span>
              )}
            </li>
          </ul>
        </div>
        {cart.length < 1 && <span className="plate-image" />}
        <div className="w-100">
          <Collapse collapsible="header">
            <Panel header="Order Details" key="1">
              {cart.length > 0 &&
                cart.map((prod1, indexxxxxx) => {
                  return prod1.instruction.map((prod, index1) => {
                    return (
                      <>
                        <ListGroupItem
                          style={{
                            textAlign: "center",
                            display: "inline",
                            boxShadow: "0px 1px 4px 1px rgb(0 0 0 / 18%",
                            border: "1px solid #162A3E"
                          }}
                          key={prod.productName}
                        >
                          <span className="row">
                            <span
                              className="col-5"
                              style={{
                                marginLeft: "-10px",
                                fontWeight: "bold"
                              }}
                            >
                              {prod.productName}
                              {location.pathname !== "/checkout/pickup/otp" &&
                                location.pathname !== "/checkout/pickup" && (
                                  <>
                                    <br />
                                    <button
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        removeItemFromCart(prod1._id)
                                      }
                                      className="link-button"
                                    >
                                      <small
                                        style={{
                                          backgroundColor: "#1B2F44",
                                          padding: "5px",
                                          color: "white"
                                        }}
                                      >
                                        Fjern
                                      </small>
                                    </button>
                                  </>
                                )}
                            </span>
                            <small className="col-3 cart-quantity">
                              x {prod.quantity}
                            </small>
                            <span className="col-4">
                              {" "}
                              Kr. <strong>{prod.quantity * prod1.price}</strong>
                            </span>
                          </span>
                          <span className="row mt-3 ml-4 ">
                            {prod.extraInstructions
                              ? `Spiciness-${prod.extraInstructions}`
                              : ""}
                          </span>
                          <span className="row mt-3 ml-4">
                            {prod.instruction
                              ? `instruction-${prod.instruction}`
                              : ""}
                          </span>
                          <span className="row">
                            <ListGroup className="cardHover">
                              {prod1.extraItems &&
                                prod1.extraItems[index1].map((item, ix) => {
                                  return (
                                    <ListGroupItem
                                      style={{ textAlign: "left" }}
                                      key={item._id}
                                    >
                                      <span className="col-5 p-0" style={{}}>
                                        {item.name}
                                      </span>
                                      <small className="col-2 cart-quantity p-0">
                                        x 1
                                      </small>
                                      <span className="col-4" style={{}}>
                                        {" "}
                                        Kr. <strong>{item.price}</strong>
                                      </span>
                                      {location.pathname ===
                                        "/checkout/pickup/otp" ||
                                      location.pathname ===
                                        "/checkout/pickup" ? (
                                        ""
                                      ) : (
                                        <span className="col-1 btnSize">
                                          <Button
                                            onClick={
                                              () =>
                                                minusButtonclicked(
                                                  prod1._id,
                                                  item._id,
                                                  index1
                                                )

                                              // removeExtraFromCart(
                                              //   prod._id,
                                              //   item._id,
                                              //   ix
                                              // )
                                            }
                                            className="btn btn-danger btn-sm"
                                          >
                                            -
                                          </Button>
                                        </span>
                                      )}
                                    </ListGroupItem>
                                  );
                                })}
                            </ListGroup>
                          </span>
                        </ListGroupItem>
                      </>
                    );
                  });
                })}
              <span className="totaltcl">
                <br />
                <p style={{ float: "left" }}>Totalt</p>
                <p style={{ float: "right", marginRight: 3 }}>
                  Kr. {(totalPrice / taxValue).toFixed(2)}
                </p>
              </span>
              <span className="totaltcl">
                <p style={{ float: "left" }}>Moms</p>
                <p style={{ float: "right", marginRight: "2px" }}>
                  Kr. {(totalPrice - totalPrice / taxValue).toFixed(2)}
                </p>
              </span>
              {(location.pathname === "/checkout/pickup/otp" ||
                location.pathname === "/checkout/deliver" ||
                location.search == "?vipps=true") &&
              orderType === "Drive Out" ? (
                <span className="totaltcld">
                  <p style={{ float: "left" }}>
                    Levering mva{" "}
                    <b style={{ fontSize: "12px" }}>
                      ({Number(driveOC - 100)}%)
                    </b>
                  </p>
                  <p style={{ float: "right" }}>
                    Kr.{" "}
                    {(
                      driveoutCharge -
                      (driveoutCharge * 100) / driveOC
                    ).toFixed(2)}
                  </p>
                  <p style={{ float: "left" }}>Leveringskost</p>
                  <p style={{ float: "right" }}>
                    Kr. {((driveoutCharge * 100) / driveOC).toFixed(2)}
                  </p>
                  <p className="subtotal" style={{ float: "left" }}>
                    Totalt <strong>({totalQuantity} Items)</strong>
                  </p>
                  <p style={{ float: "right" }} className="price">
                    Kr.{" "}
                    <strong>{(totalPrice + driveoutCharge).toFixed(2)}</strong>
                  </p>
                </span>
              ) : (
                <span className="totaltcl">
                  <p className="subtotal" style={{ float: "left" }}>
                    Totalt <strong>({totalQuantity} Items)</strong>
                  </p>
                  <p style={{ float: "right" }} className="price">
                    Kr. <strong>{totalPrice.toFixed(2)}</strong>
                  </p>
                </span>
              )}
            </Panel>
          </Collapse>
        </div>
      </Card>
    </>
  );
};

Cart.propTypes = {
  cart: PropTypes.array,
  totalPrice: PropTypes.number,
  totalQuantity: PropTypes.number,
  removeItemFromCart: PropTypes.function,
  removeExtraFromCart: PropTypes.function
};

export default withRouter(Cart);
