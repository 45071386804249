import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DeliveryForm from "./DeliveryForm";
import OTP from "./OTP";

// import Autocomplete from 'react-google-autocomplete';

// const Deliveryw = () => {
//   return (
//     <>
//     <Switch>
//       <Route exact path = "/checkout/delivery" Component = {DeliveryForm}/>
//       <Route exact path = "/checkout/delivery/otp" Component = {OTP} />
//     </Switch>
//     </>
//   )
// }

{
  /* <Card className="checkout-card">
<CardHeader>
  <strong>Deliver Address</strong>
</CardHeader>
<CardBody>
  <CardText>
    <Form>
      <Row>
        <Col md="6">
        <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Enter your address"
              value={firstName}
              onChange = {change}
              disabled = {userChecked}
            />
          </FormGroup>
          </Col>
          <Col md="6">
        <FormGroup>
            <Label for="address">Address</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Enter your last name"
              value={lastName}
              onChange = {change}
              disabled = {userChecked}
            />
          </FormGroup>
          </Col>
          <Col md="6">
          <FormGroup>
            <Label for="address">Address</Label>
            <Input
              type="text"
              name="address"
              id="address"
              placeholder="Enter your address"
              value
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input
              type="password"
              name="password"
              id="examplePassword"
              placeholder="password placeholder"
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  </CardText>
  <Button>Submit</Button>
</CardBody>
<CardFooter>Footer</CardFooter>
</Card> */
}

class Delivery extends Component {
  render() {
    return (
        <Switch>
          <Route exact path="/checkout/deliver" component={DeliveryForm} />
          <Route exact path="/checkout/delivery/otp" component={OTP} />
        </Switch>
    );
  }
}

export default Delivery;
