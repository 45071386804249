import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import globalConst from "../helpers/global";
import loadingGif from "../assets/loading-gif.gif";
import wentWrong from "../assets/error-gif.gif";
import paySuccess from "../assets/success.gif";
import { websiteurl } from "../helpers/global";

function ReserveTableFallbackDintreero(props) {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = queryString.parse(props.location.search);
        console.log("id", query.merchant_reference);
        console.log("query.transaction_id", query.transaction_id);

        if (query.error === "cancelled") {
          setLoading(false);
          setIsCancelled(true);
          return;
        }

        const response = await axios.post(
          `${globalConst.serverip}api/v1/public/confirmResreveTablePaymentdinterro`,
          { id: query.merchant_reference, transactionId: query.transaction_id },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        if (response.data.status === 200) {
          console.log("response after payment confirm in dinterro", response);
          setLoading(false);
        } else {
          setLoading(false);
          setIsError(true);
          setErrorMessage(response.data.message || "Unknown error occurred");
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
        setErrorMessage(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Server error occurred"
        );
        console.error("Error:", error.response || error);
      }
    };

    fetchData();
  }, [props.location.search]);

  if (loading) {
    return (
      <div className="loading-gif">
        <img src={loadingGif} alt="Loading" width="80px" />
      </div>
    );
  }

  if (isCancelled) {
    return (
      <div className="error-page d-flex flex-column justify-content-center align-items-center">
        <img src={wentWrong} alt="Error" width="150px" />
        <h3 className="text-white">Payment Cancelled</h3>
        <p className="text-white">
          Your payment was cancelled. Please try again or contact support if the
          issue persists.
        </p>
        <a
          className="orderBtn text-white px-5 py-2"
          style={{ background: "#12c751", borderRadius: 5 }}
          href={`${websiteurl}`}
        >
          Return
        </a>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="error-page d-flex flex-column justify-content-center align-items-center">
        <img src={wentWrong} alt="Error" width="150px" />
        <h3 className="text-white">Something went wrong</h3>
        <p className="text-white">{errorMessage}</p>
        <a
          className="orderBtn text-white px-5 py-2"
          style={{ background: "#12c751", borderRadius: 5 }}
          href={`${websiteurl}`}
        >
          Return
        </a>
      </div>
    );
  }

  return (
    <div className="success-payment d-flex flex-column justify-content-center align-items-center">
      <img src={paySuccess} alt="Success" width="250px" />
      <h3 className="text-white">Payment Successful!</h3>
      <p className="text-white">
        Thank you for the Payment. You'll get the confirmation SMS or E-Mail
        from the Restaurant soon.
      </p>
      <a
        className="orderBtn text-white px-5 py-2"
        style={{ background: "#12c751", borderRadius: 5 }}
        href={`${websiteurl}`}
      >
        Return
      </a>
    </div>
  );
}

export default ReserveTableFallbackDintreero;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import globalConst from "../helpers/global";
// import queryString from "query-string";
// import loadingGif from "../assets/loading-gif.gif";
// import wentWrong from "../assets/error-gif.gif";
// import { useHistory } from "react-router-dom";
// import paySuccess from "../assets/success.gif";

// const ReserveTableFallbackDintreero = (props) => {
//   const [loading, setLoading] = useState(true);
//   const [isError, setIsError] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const history = useHistory();

//   const handleGoBack = () => {
//     history.goBack(); // Goes back to the previous page
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const query = queryString.parse(props.location.search);
//         console.log("id", query.merchant_reference);
//         console.log("query.transaction_id", query.transaction_id);

//         const response = await axios.post(
//           `${globalConst.serverip}api/v1/public/confirmResreveTablePaymentdinterro`,
//           { id: query.merchant_reference, transactionId: query.transaction_id },
//           {
//             headers: {
//               "Content-Type": "application/json"
//             }
//           }
//         );

//         if (response.data.status === 200) {
//           console.log("response after payment confirm in dinterro", response);
//           setLoading(false);
//         } else {
//           setLoading(false);
//           setIsError(true);
//           setErrorMessage(response.data.message || "Unknown error occurred");
//         }
//       } catch (error) {
//         setLoading(false);
//         setIsError(true);
//         setErrorMessage(
//           error.response && error.response.data && error.response.data.message
//             ? error.response.data.message
//             : "Server error occurred"
//         );
//         console.error("Error:", error.response || error);
//       }
//     };

//     fetchData();
//   }, [props.location.search]);

//   return loading ? (
//     <div className="loading-gif">
//       <img src={loadingGif} alt="Loading" width="80px" />
//     </div>
//   ) : isError ? (
//     <div className="error-page d-flex flex-column justify-content-center align-items-center">
//       <img src={wentWrong} alt="Error" width="150px" />
//       <h3>Something went wrong</h3>
//       <p>{errorMessage}</p>{" "}
//       <a
//         className="orderBtn text-white"
//         href="/restaurant/reservetable/rooster"
//       >
//         Return
//       </a>
//     </div>
//   ) : (
//     <div className="success-payment d-flex flex-column justify-content-center align-items-center">
//       <img src={paySuccess} alt="Success" width="250px" />
//       <h3 className="text-white">Payment Successful!</h3>
//       <p className="text-white">
//         Thank you for the Payment. You'll get the confirmation SMS or E-Mail
//         from the Restaurant soon.
//       </p>
//       <a
//         className="orderBtn text-white px-5 py-2"
//         style={{ background: "#12c751", borderRadius: 5 }}
//         onClick={handleGoBack}
//       >
//         Return
//       </a>
//     </div>
//   );
// };

// export default ReserveTableFallbackDintreero;
