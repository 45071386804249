import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./font.css";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Button
} from "reactstrap";
import { connect } from "react-redux";
// import
import {
  checkDeliverUser,
  handleDeliveryForChange,
  editUser
} from "../actions";
import countryCodesList from "country-codes-list";

var mailcheck = false;
var fnCheck = false;
var lnCheck = false;
var numberCheck = false;
var forminvalid = true;
var cityCheck = false;
var stateCheck = false;
var pinCheck = false;
var countryCheck = false;
var Router = require("react-router");
const DeliveryForm = ({
  userChecked,
  firstName,
  lastName,
  email,
  phoneNo,
  line1,
  line2,
  city,
  state,
  pin,
  country,
  branchId,
  slug,
  dispatch
}) => {
  const [countryCode, setcountryCode] = useState(47);
  const myCountryCodesObject = countryCodesList.customList(
    "countryCode",
    "[{countryCode}]:{countryCallingCode}"
  );
  const allCountryCodes = Object.values(myCountryCodesObject);
  const findUser = (e) => {
    e.preventDefault();
    dispatch(
      checkDeliverUser(
        email,
        firstName,
        lastName,
        phoneNo,
        line1,
        line2,
        city,
        state,
        pin,
        country,
        branchId,
        countryCode
      )
    );
  };
  const change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleDeliveryForChange(name, value));
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let al = /^[ a-zA-ZøØæÆåÅ ]+$/;
    let nu = /^[0-9]{8,10}$/;
    let pi = /^[0-9]{3,6}$/;
    if (name === "email") {
      if (value && re.test(value) && name === "email") {
        mailcheck = false;
        forminvalid = false;
      } else {
        forminvalid = true;
        mailcheck = true;
      }
    } else if (name === "firstName") {
      if (value && al.test(value)) {
        forminvalid = false;
        fnCheck = false;
      } else {
        fnCheck = true;
        forminvalid = true;
      }
    } else if (name === "lastName") {
      if (value && al.test(value)) {
        lnCheck = false;
        forminvalid = false;
      } else {
        lnCheck = true;
        forminvalid = true;
      }
    } else if (name === "phoneNo") {
      if (value && nu.test(value)) {
        numberCheck = false;
        forminvalid = false;
      } else {
        numberCheck = true;
        forminvalid = true;
      }
    } else if (name === "city") {
      if (value && al.test(value)) {
        cityCheck = false;
        forminvalid = false;
      } else {
        cityCheck = true;
        forminvalid = true;
      }
    } else if (name === "state") {
      if (value && al.test(value)) {
        stateCheck = false;
        forminvalid = false;
      } else {
        stateCheck = true;
        forminvalid = true;
      }
    } else if (name === "pin") {
      if (value && pi.test(value)) {
        pinCheck = false;
        forminvalid = false;
      } else {
        pinCheck = true;
        forminvalid = true;
      }
    } else if (name === "country") {
      if (value && al.test(value)) {
        countryCheck = false;
        forminvalid = false;
      } else {
        countryCheck = true;
        forminvalid = true;
      }
    }
  };
  const countryCodeChanged = (e) => {
    let code = e.target.value.split(":")[1];
    setcountryCode(code);
  };

  const edit = () => {
    dispatch(editUser());
  };
  return (
    <Card className="checkout-card">
      <CardHeader>
        Kundedetaljer
        {/* KUNDEDETALJER */}
        {userChecked && (
          <button className="link-button float-right" onClick={edit}>
            Edit
          </button>
        )}
      </CardHeader>
      <CardBody className="bg">
        <Form onSubmit={(e) => findUser(e)}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="firstNname">Fornavn</Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="Fornavn"
                  className="textField"
                  value={firstName}
                  invalid={fnCheck}
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastNname">Etternavn</Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="Etternavn"
                  value={lastName}
                  className="textField"
                  invalid={lnCheck}
                  onChange={change}
                  disabled={userChecked}
                />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Label for="email">E-Post</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="E-Post"
                  className="textField"
                  value={email}
                  invalid={mailcheck}
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="select">Landskode</Label>
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  className="textField"
                  onChange={(e) => countryCodeChanged(e)}
                  defaultValue={"[BV]:47"}
                >
                  {allCountryCodes &&
                    allCountryCodes.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label for="phoneNo">Telefon</Label>
                <Input
                  type="text"
                  name="phoneNo"
                  id="phoneNo"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="Telefone"
                  className="textField"
                  value={phoneNo}
                  invalid={numberCheck}
                  onChange={change}
                  maxLength="10"
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>

            <Col md={12}>
              <h3>Adresse</h3>
            </Col>
            <hr />

            <Col md={6}>
              <FormGroup>
                <Label for="line1">Adresse</Label>
                <Input
                  type="text"
                  name="line1"
                  id="line1"
                  autosuggest="off"
                  autocomplete="off"
                  className="textField"
                  placeholder="linje 1"
                  value={line1}
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="address">Adresse</Label>
                <Input
                  type="text"
                  name="line2"
                  id="line2"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="Linje 2"
                  className="textField"
                  value={line2}
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="address">By</Label>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="By"
                  className="textField"
                  value={city}
                  invalid={cityCheck}
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="address">Stat</Label>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="Stat"
                  className="textField"
                  value={state}
                  invalid={stateCheck}
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="address">Pin</Label>
                <Input
                  type="text"
                  name="pin"
                  id="pin"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="Pin"
                  value={pin}
                  invalid={pinCheck}
                  className="textField"
                  maxLength="5"
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="address">Land</Label>
                <Input
                  type="text"
                  name="country"
                  id="country"
                  autosuggest="off"
                  autocomplete="off"
                  placeholder="Land"
                  className="textField"
                  value={country}
                  invalid={countryCheck}
                  onChange={change}
                  disabled={userChecked}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          {!userChecked && (
            <Button
              type="submit"
              disabled={forminvalid}
              color="success"
              outline
              className="lagreBtn"
            >
              Lagre
            </Button>
          )}
        </Form>
        <p
          style={{ color: "grey", marginTop: 10, float: "right", fontSize: 13 }}
        >
          Vennligst referer vår :
          {/* <a href="https://web.a-board.world/merchantterm" target="_blank">
            {" "}
            <u>Salg Vilkår og betingelser</u>
          </a> */}
          <Link to={"/restaurantterm/" + slug}>Salg Vilkår og betingelser</Link>
        </p>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = ({ order, restaurant }) => {
  let branchId = restaurant.id;
  let slug = restaurant.slug;

  const {
    email,
    phoneNo,
    firstName,
    lastName,
    line1,
    line2,
    city,
    state,
    pin,
    country,
    userChecked,
    activeId,
    otp
  } = order;
  return {
    email,
    phoneNo,
    firstName,
    lastName,
    line1,
    line2,
    city,
    state,
    pin,
    country,
    userChecked,
    activeId,
    otp,
    branchId,
    slug
  };
};

export default connect(mapStatetoProps)(DeliveryForm);
