const globalConst = {
  serverip: "https://api.a-board.tech/", // tech server
  // serverip: "https://api.a-board.world/", // tech server
  // serverip: "http://localhost:8080/", // local server

  s3url: "https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com/"
};
export default globalConst;
export const serverip = "https://api.a-board.tech/";
// export const serverip = "https://api.a-board.world/";
export const s3url =
  "https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com/";
// export const websiteurl = "https://treedrive.no/";
export const websiteurl = "https://treedrive.tech/";
