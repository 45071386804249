import React, { Component } from "react";
import { Jumbotron, Container, Spinner } from "reactstrap";
import globalConst from "../helpers/global";
import { Space, Table, Tag } from "antd";

const dataSource = [
  {
    process: " Order Edit /Cancellation/Compensation/Payment Failure",
    paymentmethod: "Credit Card/ Debit Card",
    refundprocess: "Source",
    tat: "5-7 Business Days"
  },
  {
    process: " Order Edit /Cancellation/Compensation/Payment Failure",
    paymentmethod: "Vipps",
    refundprocess: "Source",
    tat: "5-7 Business Days"
  },
  {
    process: " Order Edit /Cancellation/Compensation/Payment Failure",
    paymentmethod: "TFL Gift Card",
    refundprocess: "Source",
    tat: "24 Hours"
  }
];

const columns = [
  {
    title: "Process",
    dataIndex: "process",
    key: "process"
  },
  {
    title: "Payment Method",
    dataIndex: "paymentmethod",
    key: "paymentmethod"
  },
  {
    title: "Refund Process",
    dataIndex: "refundprocess",
    key: "refundprocess"
  },
  {
    title: "TAT",
    dataIndex: "tat",
    key: "tat"
  }
];

export default class MerchantTerm extends Component {
  state = {
    rest: null,
    isloading: false
  };
  componentDidMount() {
    const restaurantId = this.props.match.params.slug;

    this.getRestaurantInfo(restaurantId);
  }

  getRestaurantInfo = (id) => {
    this.setState({
      isloading: true
    });
    fetch(`${globalConst.serverip}public/getpbranchinfo/${id}`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ rest: json.data, isloading: false });

        return json.data;
      })
      .catch((error) => {
        this.setState({
          isloading: true
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isloading ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Spinner color="success" type="grow" />
          </div>
        ) : (
          <div style={{ paddingBottom: 30 }}>
            <Container>
              {/* <img
            style={{ alignSelf: "center" }}
            src={require("../assets/fabro.png")}
          ></img> */}
            </Container>
            <Jumbotron fluid>
              <Container fluid>
                <h1 style={{ textAlign: "center" }}>
                  Standard salgsbetingelser for forbrukerkjøp av varer over
                  Internett
                </h1>
                <p style={{ textAlign: "center" }}>
                  Versjon 2.0 revidert oktober 2015. Oppdatert januar 2018.{" "}
                </p>
                {/* <p className="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p> */}
              </Container>
            </Jumbotron>

            <Container className="salesStyle">
              <h3>Introduksjon:</h3>
              <p>
                Dette kjøpet er underlagt nedenstående standard vilkår og
                betingelser for forbrukerkjøp av varer over Internett.
                Forbrukerkjøp over internett reguleres i hovedsak av
                avtaleloven, forbrukerkjøpsloven, markedsføringsloven,
                angrerettloven og handelsloven, og disse lovene gir forbrukeren
                ufravikelige rettigheter. Lovene er tilgjengelige på{" "}
                <a href="https://lovdata.no/" target="_blank">
                  www.lovdata.no
                </a>{" "}
                Vilkårene i denne avtalen skal ikke forstås som noen begrensning
                av de lovfestede rettighetene, men angir partenes viktigste
                rettigheter og forpliktelser for handelen.
              </p>
              <p>
                Salgsvilkårene er utarbeidet og anbefalt av Forbrukertilsynet.
                For en bedre forståelse av disse salgsbetingelsene, se{" "}
                <a href="https://www.forbrukertilsynet.no/" target="_blank">
                  Forbrukertilsynets veileder.
                </a>
                .{" "}
              </p>
              <h3> 1. Avtalen :</h3>
              <p>
                Avtalen består av disse salgsvilkårene, avbestillinger gitt i
                bestillingsløsningen og eventuelle særskilte avtalte vilkår. Ved
                eventuell konflikt mellom opplysningene har det som spesifikt
                snakkes mellom partene forrang, forutsatt at det ikke er i strid
                med ufravikelig lovgivning.{" "}
              </p>
              <p>
                Avtalen vil også kompletteres av relevante lovbestemmelser som
                regulerer kjøp av varer mellom næringsdrivende og forbrukere.{" "}
              </p>
              <h3> 2. Partene :</h3>
              <p style={{ fontWeight: "bold" }}>Selger er </p>
              <p style={{ fontWeight: "bold" }}>
                Firma: {this.state.rest ? this.state.rest.name : ""}
              </p>
              <p style={{ fontWeight: "bold" }}>
                Plattform:{" "}
                <a href={this.state.rest ? this.state.rest.website : ""}>
                  {this.state.rest ? this.state.rest.website : ""}
                </a>
              </p>
              <p style={{ fontWeight: "bold" }}>
                {" "}
                Adresse:
                <span style={{ color: "grey" }}>
                  {" "}
                  {this.state.rest && this.state.rest.address
                    ? this.state.rest.address.line2 +
                      " " +
                      this.state.rest.address.city +
                      ", " +
                      this.state.rest.address.state +
                      ", " +
                      this.state.rest.address.country +
                      ", " +
                      this.state.rest.address.pin
                    : ""}
                </span>
              </p>
              <p>
                E-post:{" "}
                <a href="">
                  {this.state.rest ? this.state.rest.restaurantEmail : ""}{" "}
                </a>
                <p>
                  Tel: + 47 {this.state.rest ? this.state.rest.phoneNo : ""}{" "}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Org.nr: {this.state.rest ? this.state.rest.taxNo : ""}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Branch code:{" "}
                  {this.state.rest && this.state.rest.address
                    ? this.state.rest.address.line1
                    : ""}
                </p>
              </p>
              <p>og omtales i det følgende som selger.</p>
              <p>
                Kjøper er forbrukeren som foretar bestillingen og omtales i det
                følgende som Kjøper.{" "}
              </p>
              {/* <p>
                {" "}
                <span style={{ fontWeight: "bold" }}> PARTENE:</span>OM DATA AS
                er et teknologileverandørselskap der du enkelt kan legge inn
                bestillinger og benytte andre tjenester også.{" "}
              </p> */}
              <h3>3. Tatt </h3>
              <p>
                Oppgitt pris for varen og tjenestene er totalprisen kjøperen
                skal betale. Denne prisen inkluderer alle skatter og
                tilleggskostnader. Merkostnader som selger før kjøpet ikke har
                informert om, skal kjøper ikke bære.{" "}
              </p>
              <h3>4. Avtaleinngåelse </h3>
              <p>
                Avtalen er bindende for begge parter når kjøper har sendt sin
                bestilling til selger.
              </p>
              <p>
                Avtalen er imidlertid ikke bindende dersom det har vært skrive-
                eller tastefeil i tilbudet fra selger i bestillingsløsningen i
                nettbutikken eller i kjøpers bestilling, og motparten da eller
                burde ha innsett at en slik feil har skjedd.{" "}
              </p>
              <h3>Innholdsfortegnelse: </h3>
              <p>1. Avtalen</p>
              <p>2. Partene</p>
              <p>3. Tatt</p>
              <p>4. Avtaleinngåelse </p>
              <p>5. Betaling </p>
              <p>6. Levering </p>
              <p>7. Risikoen for varen</p>
              <p>8. Angrerett</p>
              <p>
                9. Forsinkelse og manglende levering - kjøpers rettigheter og
                frist for å fremme krav.
              </p>
              <p>10. Mangel på varen - kjøpers rettigheter og garantifrist</p>
              <p>11. Selgers rettigheter ved kjøpers mislighold</p>
              <p>12. Garantert </p>
              <p>13. Personopplysninger</p>
              <p>14. Konfliktløsning </p>
              <h3>5. Betaling </h3>
              <p>
                Selger kan kreve betaling for varen fra den er lagt inn i
                nettbestillingen og varen sendes fra selger til kjøper samme
                dag.
              </p>
              <p>
                Dersom kjøper benytter kreditt- eller debetkort på
                betalingstidspunktet, kan selger reservere kjøpesummen på kortet
                ved bestilling. Kortet vil bli belastet samme dag som varen
                sendes.{" "}
              </p>
              <p>
                Ved betaling med faktura utstedes faktura til kjøper ved
                forsendelse av varen.
                 {/* Betalingsfristen er oppgitt på fakturaen
                og er minst 7 dager fra mottak, denne tjenesten er kun for
                enkelte brukere og er ikke en standardtjeneste fra OM DATA AS.{" "} */}
              </p>
              <p>Kjøpere under 18 år kan ikke betale med følgende faktura. </p>
              <h3>6. Levering </h3>
              <p>
                Levering skjer når kjøper, eller dennes representant, har
                overtatt varer (mat & drikke samt leveringskostnader).
                Leveringskostnader gjelder ikke ved egen henting.
              </p>
              <p>
                Dersom leveringstiden ikke er oppgitt i bestillingsløsningen,
                skal selger levere varen til kjøper uten ugrunnet opphold og
                senest samme dag etter bestilling fra kunden. Varen skal leveres
                til kjøper med mindre annet er særskilt avtalt mellom partene.
              </p>
              <h3>7. Risikoen for varen</h3>
              <p>
                Risikoen for varen går over på kjøperen når han eller dennes
                representant har fått varen levert i henhold til punkt 6.{" "}
              </p>
              <h3>8. Angrerett </h3>
              <p>
                Med mindre avtalen er unntatt fra angreretten, kan kjøper heve
                kjøpet av varen i henhold til angrerettloven.{" "}
              </p>
              <p>
                Kjøper skal varsle selger om bruk av angreretten innen 14 dager
                fra det tidspunkt fristen begynner å løpe. Fristen inkluderer
                alle kalenderdager. Dersom fristen går ut på en lørdag eller
                helligdag forlenges fristen til nærmeste virkedag.{" "}
              </p>
              <p>
                Angrefristen anses overholdt dersom melding er sendt før
                fristens utløp. Kjøper har bevisbyrden for at angreretten er
                benyttet, og meldingen bør derfor skje skriftlig (angreskjema,
                e-post eller brev).{" "}
              </p>
              <p>Utsettelsesperioden begynner å løpe: </p>
              <ul>
                <li>
                  <p>
                    Ved kjøp av enkeltstående varer løper angrefristen fra dagen
                    etter at varen(e) er mottatt.{" "}
                  </p>
                </li>
                <li>
                  Dersom et abonnement selges, eller innebærer avtale
                  regelmessig levering av identiske varer, løper fristen fra
                  dagen etter at første forsendelse er mottatt.{" "}
                </li>
                <li>
                  Ved kjøp av mat- og drikkevarer kan det ikke gis angrerett.
                  Selger kan dermed ikke passere noen form for angrerett når det
                  gjelder kjøp av mat og drikke fra restauranten. Dette gjelder
                  kun ved avbestilling av varer, som skal skje raskt etter
                  bestilling av varer og må godkjennes av restauranten som har
                  mottatt den bestillingen.
                </li>
                <li>
                  <p>
                    Består kjøpet av flere leveranser, løper angrefristen fra
                    dagen etter siste leveranse er mottatt.
                  </p>
                </li>
              </ul>
              <p>
                Angrefristen forlenges til 12 måneder etter utløpet av den
                opprinnelige fristen dersom selger ikke før avtaleinngåelse
                oppgir at det foreligger angrerett og standardisert angreskjema.
                Det samme gjelder ved manglende informasjon om vilkår, frister
                og fremgangsmåte for bruk av angreretten. Dersom den
                næringsdrivende sørger for å gi fra seg opplysningene i løpet av
                disse 12 månedene, utløper angrefristen likevel 14 dager etter
                den dagen kjøper mottok opplysningene.
              </p>{" "}
              <p>
                {" "}
                Kjøper kan prøve eller teste varen på en forsvarlig måte for å
                fastslå varens art, egenskaper og funksjon, uten angrerett.
                Dersom testing eller testing av varen går utover det som er
                forsvarlig og nødvendig, kan kjøper være ansvarlig for eventuell
                redusert verdi av varen.{" "}
              </p>
              <p>
                Selger plikter å tilbakebetale kjøpesummen – men til kjøper uten
                ugrunnet opphold, og senest 14 dager fra selgeren fikk melding
                om kjøpers beslutning om å benytte angreretten. Selger har rett
                til å holde tilbake betaling inntil han har mottatt varen fra
                kjøper, eller inntil kjøper har fremlagt dokumentasjon på at
                varen er sendt tilbake.{" "}
              </p>
              <h3>
                9. Forsinkelse og manglende levering – kjøpers rettigheter og
                kravs frist{" "}
              </h3>
              <p>
                Dersom selgeren ikke leverer varen eller leverer den for sent i
                henhold til avtalen mellom partene, og dette ikke skyldes
                kjøperen eller forhold på kjøpers side, kan kjøperen etter
                reglene i kapittel 5 i stk. forbrukerkjøpsloven, etter
                omstendighetene holde tilbake kjøpesummen, kreve oppfyllelse,
                heve avtalen og/eller kreve erstatning fra selger.
              </p>
              <p>
                Ved krav om kontraktsbrudd bør meldingen være skriftlig for
                bevisformål (f.eks. e-post).{" "}
              </p>
              <ul>
                <li>
                  <p style={{ fontWeight: "bold" }}>Oppfyllelse</p>
                </li>
                <p>
                  Kjøper kan opprettholde kjøpet og kreve oppfyllelse av selger.
                  Kjøper kan imidlertid ikke kreve oppfyllelse dersom det er en
                  hindring som selger ikke kan overkomme, eller dersom
                  oppfyllelse vil medføre en så stor ulempe eller kostnad for
                  Selger at det er vesentlig uenig i kjøpers interesse i at
                  selger oppfyller. Skulle vanskelighetene falle bort innen
                  rimelig tid, kan kjøperen likevel kreve oppfyllelse.{" "}
                </p>
                <p>
                  Kjøperen taper sin rett til å kreve oppfyllelse om han eller
                  hun venter urimelig lenge med å fremme kravet.
                </p>
              </ul>
              <ul>
                <li>
                  <p style={{ fontWeight: "bold" }}>Heving</p>
                </li>
                <p>
                  Dersom selger ikke leverer varen på leveringstidspunktet, skal
                  kjøper oppfordre Selger til å levere innen en rimelig
                  tilleggsfrist for oppfyllelse. Dersom selger ikke leverer
                  varen innen tilleggsfristen, kan kjøper heve kjøpet.
                </p>
                <p>
                  Kjøper kan imidlertid heve kjøpet umiddelbart dersom selger
                  nekter å levere varen. Det samme gjelder dersom levering til
                  avtalt tid var avgjørende for avtaleinngåelsen, eller dersom
                  kjøper har meddelt selger at leveringstidspunktet er
                  avgjørende.{" "}
                </p>
                <p>
                  Dersom varen leveres etter tilleggsfristen fastsatt av
                  forbrukeren eller etter leveringstidspunktet som var
                  avgjørende for avtaleinngåelsen, må krav om heving fremsettes
                  innen 24 timer etter at kjøper fikk kjennskap til leveransen.{" "}
                </p>
              </ul>
              <ul>
                <li>
                  <p style={{ fontWeight: "bold" }}>Erstatning</p>
                </li>
                <p>
                  Kjøper kan kreve erstatning for ethvert tap som er påført som
                  følge av forsinkelsen. Dette gjelder imidlertid ikke dersom
                  selger beviser at forsinkelsen skyldes hindringer utenfor
                  selgers kontroll som ikke med rimelighet kunne vært tatt i
                  betraktning på avtaletidspunktet, unngått eller overvunnet
                  konsekvensene av.
                </p>
              </ul>
              <h3>10. Mangel på varen - kjøpers rettigheter og garantifrist</h3>
              <p>
                Dersom det er en mangel ved varen, skal kjøper innen rimelig tid
                (30 minutter) etter at den ble oppdaget eller burde vært
                oppdaget, gi selger beskjed om at han eller hun vil vise til
                mangelen. Kjøper har alltid annonsert i tide dersom det skjer
                innen 30 minutter etter at mangelen ble oppdaget eller burde
                vært oppdaget. Reklamasjon kan gjøres senest 30 minutter etter
                at kjøper overtok varen.{" "}
              </p>
              <p>
                Dersom varen har en mangel og dette ikke skyldes kjøper eller
                forhold på kjøpers side, kan kjøper etter reglene i
                forbrukerkjøpsloven kapittel 6 under forholdene holde tilbake
                kjøpesummen, velge mellom retting. og omlevering, kreve
                prisrabatt, kreve avtalen hevet og/eller kreve erstatning fra
                selger.{" "}
              </p>
              <p>Klager til selger skal fremsettes skriftlig.</p>
              <ul>
                <li>
                  <p style={{ fontWeight: "bold" }}>Retting eller omlevering</p>
                </li>
                <p>
                  Kjøper kan velge mellom å kreve mangelen rettet eller levering
                  av lignende ting. Selger kan likevel protestere mot kjøpers
                  krav dersom gjennomføring av kravet er umulig eller selger
                  pådrar seg urimelige kostnader. Utbedring eller utskifting
                  skal skje innen rimelig tid. I utgangspunktet har ikke Selger
                  rett til å gjøre mer enn to utbedringsforsøk for samme mangel.
                </p>
              </ul>
              {/* <ul>
                <li>
                  <p style={{ fontWeight: "bold" }}>Prisavslag</p>
                </li>
                <p>
                  Kjøperen kan be om en passende rabatt dersom varen ikke rettes
                  eller avstås. Dette betyr at forholdet mellom rabattert og
                  avtalt pris tilsvarer forholdet mellom verdien av tingen i
                  mangelfull og kontraktsmessig stand. Dersom særlige grunner
                  taler for dette, kan prisvegringen i stedet settes lik
                  mangelens betydning for kjøper.
                </p>
              </ul>
              <ul>
                <li>
                  <p style={{ fontWeight: "bold" }}>Heving</p>
                </li>
                <p>
                  Dersom varen ikke er rettet eller omlevert, kan kjøperen også
                  heve kjøpet når mangelen ikke er uvesentlig
                </p>
              </ul> */}
              <h3>11. Selgers rettigheter ved kjøpers mislighold</h3>
              <p>
                Dersom kjøper ikke betaler eller oppfyller de øvrige
                forpliktelser etter avtalen eller loven, og dette ikke skyldes
                selgeren eller forhold på selgerens side, kan selgeren etter
                reglene i forbrukeren kapittel 9. Kjøpsloven, etter
                omstendighetene tilbakeholde varen, kreve oppfyllelse av
                avtalen, kreve avtalen hevet og kreve erstatning fra kjøper.
                Selger vil også kunne kreve renter ved forsinket betaling,
                inkassogebyr og et rimelig gebyr for uavhentede varer.{" "}
              </p>
              <ul>
                <li>
                  <p style={{ fontWeight: "bold" }}>Oppfyllelse</p>
                </li>
                <p>
                  Selger kan opprettholde kjøpet og kreve at kjøper betaler
                  kjøpesummen. Dersom varen ikke blir levert, mister selger sin
                  rett dersom han venter urimelig lenge med å fremsette kravet.
                </p>
              </ul>
              <ul>
                <li>
                  <p style={{ fontWeight: "bold" }}>Høyde</p>
                </li>
                <p>
                  Selger kan heve avtalen dersom det foreligger vesentlig
                  betalingsmislighold eller annet vesentlig mislighold fra
                  Kjøpers side. Selger kan imidlertid ikke heve dersom hele
                  kjøpesummen er betalt. Dersom selger setter en rimelig
                  tilleggsfrist for oppfyllelse og kjøper ikke betaler innen
                  denne fristen, kan selger heve kjøpet.
                </p>
              </ul>
              <ul>
                <li>
                  <p>
                    Renter ved forsinket betaling/inkassogebyr Dersom kjøperen
                    ikke betaler kjøpesummen i hen- hold til avtalen, kan selger
                    kreve renter av kjøpe- summen etter forsinkelsesrenteloven.
                    Ved manglende betaling kan kreves, etter forutgående varsel,
                    bli sendt til inkasso. Kjøper kan da bli holdt ansvarlig for
                    gebyr etter inkassoloven.{" "}
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>• Gebyr ved uavhentede ikke-forskuddsbetalte varer</p>
                </li>
              </ul>
              <p>
                Dersom kjøper unnlater å hente ubetalte varer, kan selger kreve
                et gebyr fra kjøper. Gebyret skal dekke selgers faktiske
                utgifter til å levere varen til kjøper. Et slikt gebyr kan ikke
                belastes kjøpere under 18 år.{" "}
              </p>
              <h3>12. Garantert</h3>
              <p>
                Garanti gitt av selger eller produsent gir kjøper rettigheter i
                tillegg til de som kjøper allerede har i henhold til
                obligatorisk lov. En garanti innenfor- har dermed ingen
                begrensninger på kjøperens rett til reklamasjon og krav om
                forsinkelse eller mangler i henhold til paragraf 9 og 10.
              </p>
              <h3>13. Personopplysninger</h3>
              <p>
                Behandlingsansvarlig for innsamlede personopplysninger er
                selgerselskapet. Med mindre kjøper avtaler annet, kan selger i
                henhold til personopplysningsloven kun samle inn og lagre de
                personopplysningene som er nødvendige for at selgeren skal kunne
                oppfylle forpliktelsene etter avtalen. Kjøpers
                personopplysninger vil kun bli utlevert til andre dersom det er
                nødvendig for Selger for å fullføre avtalen med Kjøper, eller i
                lovpålagte tilfeller.{" "}
              </p>
              <h3>14. Konfliktløsning</h3>
              <p>
                Klager rettes til selger innen rimelig tid, jfr. pkt. 9 og 10.
                Partene skal bestrebe seg på å løse eventuelle tvister i
                minnelighet.{" "}
              </p>
              <p>E-post : {this.state.rest ? this.state.rest.restaurantEmail : ""} </p>
              <p>Tel: +47 {this.state.rest ? this.state.rest.phoneNo : ""}</p>
              <p>
                Dersom dette ikke lykkes, kan kjøper kontakte Forbrukerrådet for
                mekling. Forbrukerrådet er tilgjengelig på telefon +47 23 400
                500 eller www.forbrukerradet.no.{" "}
              </p>
              <p style={{ fontSize: 11 }}>KANSELLERING OG REFUSJONSRETNING </p>
              <p>
                Alle termer med stor forbokstav som brukes, men som ikke er
                definert her, skal ha den betydningen som er tildelt dem under
                bruksvilkårene som styrer din bruk av nettstedet vårt{" "}
                <a href="https://treedrive.no/home" target="_blank">
                  {" "}
                  www.treedrive.no{" "}
                </a>
                ("nettsiden") og vår "Tree Drive"-applikasjon for mobile og
                håndholdte enheter ("Appen"). Nettstedet og appen blir i
                fellesskap referert til som "Plattformen".{" "}
              </p>
              <h3>
                <b>Kanselering</b>
              </h3>
              <ol>
                <li>
                  Restauranten forbeholder seg retten til å kreve straff for
                  bestillingene som er begrenset til å bli kansellert av
                  restauranten av årsaker som ikke kan tilskrives Tree Drive.
                  Inkludert, men ikke begrenset til:
                  <ul>
                    <li>
                      I tilfelle adressen oppgitt av Kjøper enten er feil eller
                      faller utenfor leveringssonen;
                    </li>
                    <li>
                      Unnlatelse av å kontakte kjøper via telefon eller e-post
                      på tidspunktet for levering av ordrebestillingen;
                    </li>
                    <li>
                      Manglende levering av kjøperordre på grunn av mangel på
                      informasjon, anvisning eller autorisasjon fra kjøper på
                      leveringstidspunktet; <i>eller</i>
                    </li>
                    <li>
                      Utilgjengelighet av alle varene bestilt av kjøperen på
                      tidspunktet for bestilling av bestillingen; <i>eller</i>
                    </li>
                    <li>
                      Utilgjengelighet av alle varene bestilt av kjøperen på
                      tidspunktet for bestilling av bestillingen. Men i det
                      usannsynlige tilfellet at en vare i en bestilling ikke er
                      tilgjengelig, <br />
                      vil restauranten kontakte kjøperen på telefonnummeret som
                      ble oppgitt til oss på tidspunktet for bestillingen og
                      informere kjøperen om slik utilgjengelighet. I et slikt{" "}
                      <br />
                      tilfelle vil kjøper ha rett til å kansellere hele
                      bestillingen og har rett til
                    </li>
                    <li>
                      En refusjon til et beløp på opptil 100 % av ordreverdien.
                    </li>
                  </ul>{" "}
                  <Table
                    className="salesTable my-4"
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                  />
                </li>
                <li>
                  Ved kanselleringer av årsaker som kan tilskrives Restauranten
                  eller PDP, skal Tree Drive ikke kreve noen straff fra
                  kjøperen.
                </li>
                <li>
                  Refusjoner
                  <ul>
                    <li>
                      Kjøper kan ha rett til refusjon for forhåndsbetalte
                      bestillinger. Restauranten beholder retten til å beholde
                      straffen som skal betales av kjøperen i seksjon I (2) fra
                      beløpet som kan refunderes til ham/henne. Kjøperen skal
                      også ha rett til refusjon av forholdsmessig verdi i
                      tilfelle emballasje av en vare i en ordre eller hele
                      ordren, enten er tuklet eller skadet og kjøperen nekter å
                      akseptere på leveringstidspunktet av den nevnte grunnen;
                    </li>
                    <li>
                      Kjøper kan ha rett til en refusjon på opptil 100 % av
                      ordreverdien hvis PDP ikke leverer bestillingen på grunn
                      av en årsak som kan tilskrives enten Restaurant eller Tree
                      Drive, men slike refusjoner vil bli vurdert fra sak til
                      sak ved restauranten.
                    </li>
                    <li>
                      Vår beslutning om refusjon skal være endelig og bindende.
                    </li>
                    <li>
                      Alle refusjonsbeløp skal krediteres kjøpers konto som kan
                      være fastsatt i henhold til betalingsmekanismen etter
                      kjøpers valg, de estimerte tidslinjene er detaljert som
                      nedenfor
                    </li>
                  </ul>
                  Forutsatt at det samme kommuniseres til Tree Drive Customer
                  Care gjennom plattformen før bestillingen merkes som levert.
                </li>
                <li>
                  Spesifikke vilkår med hensyn til bruk av plattform for kjøp av
                  drikkevarer
                  <ol>
                    <li>
                      Kansellering av ikke-kunde: Restauranten forbeholder seg
                      retten til å kreve inn avbestillingsgebyret for kjøperen
                      for bestillingene som er begrenset til å bli kansellert av
                      restauranten av årsaker som ikke kan tilskrives
                      restauranten, inkludert, men ikke begrenset til:
                      <ul>
                        <li>
                          I tilfelle hvis adressen oppgitt av kjøperen enten er
                          feil eller faller utenfor Tree Drives leveringssone;
                        </li>
                        <li>
                          Unnlatelse av å kontakte kjøperen via telefon eller
                          e-post på tidspunktet for levering av bestillingen;
                        </li>
                        <li>
                          Unnlatelse av å levere kjøperens ordre på grunn av
                          mangel på informasjon, anvisning eller autorisasjon
                          fra kjøperen på leveringstidspunktet; <i>eller</i>
                        </li>
                        <li>
                          Kjøperens unnlatelse av å gi en gyldig OTP til PDP for
                          mottak av levering
                        </li>
                      </ul>
                    </li>
                    <li>
                      Refusjon: Kjøper kan ha rett til refusjon for
                      forhåndsbetalte bestillinger, ettertrekk av
                      avbestillingsgebyr, hvis noen av grunnene nevnt ovenfor
                      eller på en måte som restauranten finner passende etter
                      eget skjønn, dersom refusjon er bedt om pga. følgende
                      årsaker:
                      <ul>
                        <li>
                          Hvis bestillingen ikke kunne leveres innen 2 timer;
                        </li>
                        <li>
                          Hvis Restauranten kansellerer bestillingen på grunn av
                          årsaker som ikke kan tilskrives kjøperen, inkludert,
                          men ikke begrenset til at butikk er stengt, manglende
                          tilgjengelighet av varer, butikk som ikke betjener
                          bestillinger på nett, overbefolkning i butikk, etc.;
                        </li>
                        <li>
                          Hvis Restaurant eller Tree Drive kansellerer
                          bestillingen på grunn av årsaker som ikke kan
                          tilskrives kjøperen, inkludert, men ikke begrenset til
                          manglende tilgjengelighet av PDP-er osv.
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <p>
                    Kjøperen kan ha rett til å refundere opp til 100 % av
                    ordreverdien på grunn av de ovennevnte årsakene, avhengig av
                    utstedelsens art. Restauranten forbeholder seg retten til å
                    vurdere kansellerings- og refusjonsforespørselen og avgjøre
                    om slik kansellerings- og refusjonsforespørsel
                    tilfredsstiller noen av de ovennevnte betingelsene, i så
                    fall skal restauranten behandle kanselleringsforespørselen
                    og refusjon til kjøperen.
                  </p>
                  <p>
                    <b>Viktig merknad 1: </b>Kjøperen skal verifisere sin
                    bestilling og produktene før de gir OTP til PDP, og ethvert
                    problem med hensyn til produktet eller kjøpers bestilling
                    skal varsles umiddelbart, og kanselleringsforespørsel skal
                    legges inn før OTP sendes til PDP. Når OTP er gitt til PDP,
                    skal det anses at kjøperen har akseptert levering av sin
                    ordre, og når han/hun har akseptert levering, kan kjøperen
                    ikke kreve refusjon.
                  </p>
                  <p>
                    <b>Viktig merknad 2: </b>I tilfelle reklamasjon på et falskt
                    produkt, skal ansvaret utelukkende ligge hos selgeren som
                    selger produktet. Tree Drive er bare å forenkle
                    transaksjonene mellom selgeren og kjøperen, og derfor skal
                    Tree drive ikke påta seg noe ansvar med hensyn til
                    produktene som selges av selgeren. Tree drive fraråder
                    strengt bruk av falske produkter på Plattformen og skal
                    forbeholde seg retten til å rapportere slike hendelser til
                    berørte myndigheter for passende rettslige handlinger.
                    <br />
                    Ved uunngåelige omstendigheter som ikke kan ignoreres f.eks.
                    trafikkhinder, eller enhver nødsituasjon, vil vi i så fall
                    ikke kunne kompensere.
                  </p>
                </li>
                <li>
                  Genies avbestillingsregler
                  <ul>
                    <li>
                      Kansellering av en Hente- og slippordre etter at
                      leveringspartneren har nådd stedet, men ikke har hentet
                      varene, kan kreve et avbestillingsgebyr i størrelsesorden
                      30 % av regningsbeløpet.
                    </li>
                    <li>
                      Kansellering av en Hente- og slippordre etter at
                      leveringsansvarlig har hentet leveringsvarene vil medføre
                      et avbestillingsgebyr tilsvarende hele servicegebyret.
                    </li>
                  </ul>
                </li>
              </ol>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}
