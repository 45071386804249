import {
  ADD_ITEM_TO_CART,
  DECREASE_QUANTITY,
  DELETE_CART,
  INCREASE_QUANTITY,
  REMOVE_EXTRA_ITEM_FROM_CART,
  REMOVE_ITEM_FROM_CART
} from "../actions/cartActions";

const initialState = {
  items: [],
  grandTotal: 0,
  totalQuantity: 0
};

const findMyIndex = (arr, id) => {
  const myIndex = arr.findIndex((item) => item._uniqueId === id);
  return myIndex;
};

const calculateGrandQuantity = (items) => {
  const totalQuantity = items.reduce((acc, item1) => {
    return (acc = acc + Number(item1.quantity));
  }, 0);
  return Number(totalQuantity);
};


const calculateGrandTotal = (items) => {
  const extraItemsTotalPrice = items.map((item) => {
    return item.extraItems.reduce((acc, item1) => {
      acc = acc + Number(item1.price);
      return acc;
    }, 0);
  });

  const extraPrice = extraItemsTotalPrice.reduce((acc, item1) => {
    return (acc = acc + item1);
  }, 0);

  console.log("extraPrice", extraPrice);
  const grandTotal = items.reduce((acc, item) => {
    acc = acc + item.quantity * item.price;
    return acc;
  }, 0);
  let total = Number(grandTotal) + Number(extraPrice);
  return { gTotal: total, gExtraTotal: Number(extraPrice) };
};

const cartReducer = (state = initialState, action) => {
  // console.log("action", action);

  switch (action.type) {
    case ADD_ITEM_TO_CART:
      let myArr = [...state.items, action.payload];
      const { gTotal, gExtraTotal } = calculateGrandTotal(myArr);
      const tQuantity = calculateGrandQuantity(myArr);

      return {
        ...state,
        items: [...state.items, action.payload],
        grandTotal: gTotal,
        totalQuantity: tQuantity,
        grandExtraItemTotal: gExtraTotal
      };

    case INCREASE_QUANTITY: {
      const myArray = [...state.items];
      const index = findMyIndex(myArray, action.payload);
      myArray[index].quantity = myArray[index].quantity + 1;
      myArray[index].totalPrice =
        myArray[index].price * myArray[index].quantity;

      const { gTotal, gExtraTotal } = calculateGrandTotal(myArray);
      const tQuantity = calculateGrandQuantity(myArray);

      return {
        ...state,
        items: myArray,
        grandTotal: gTotal,
        totalQuantity: tQuantity,
        grandExtraItemTotal: gExtraTotal
      };
    }

    case DECREASE_QUANTITY: {
      const myArray = [...state.items];
      const index = findMyIndex(myArray, action.payload);
      myArray[index].quantity = myArray[index].quantity - 1;
      myArray[index].totalPrice =
        myArray[index].price * myArray[index].quantity;
      const { gTotal } = calculateGrandTotal(myArray);
      const tQuantity = calculateGrandQuantity(myArray);
      return {
        ...state,
        items: myArray,
        grandTotal: gTotal,
        totalQuantity: tQuantity,
        grandExtraItemTotal: gExtraTotal
      };
    }

    case REMOVE_ITEM_FROM_CART: {
      const myArray = [...state.items];
      const newArr = myArray.filter((item) => {
        return item._uniqueId !== action.payload;
      });

      const { gTotal } = calculateGrandTotal(newArr);
      const tQuantity = calculateGrandQuantity(newArr);
      return {
        ...state,
        items: newArr,
        grandTotal: gTotal,
        totalQuantity: tQuantity,
        grandExtraItemTotal: gExtraTotal
      };
    }

    case REMOVE_EXTRA_ITEM_FROM_CART: {
      const myArray = [...state.items];
      const index = findMyIndex(myArray, action.payload.uniqueId);
      console.log("index", index);
      const newArr = myArray[index].extraItems.filter(
        (item) => item._id !== action.payload.id
      );
      console.log("new arr", newArr);
      ///
    


      ///
      myArray[index].extraItems = newArr;
      console.log("myArrrrrrrr",myArray[index])
     
      const extraPrice =    myArray[index].extraItems.reduce((acc, item1) => {
        return (acc = acc + item1.price);
      }, 0);
      myArray[index].extraPrice=extraPrice;

      console.log("new new wxtra price",extraPrice)
      
      const { gTotal, gExtraTotal } = calculateGrandTotal(myArray);
      return {
        ...state,
        items: myArray,
        grandTotal: gTotal,
        grandExtraItemTotal: gExtraTotal
      };
    }

    case DELETE_CART: {
      return {
        items: [],
        grandTotal: 0,
        totalQuantity: 0,
        grandExtraItemTotal: 0
      };
    }
    default:
      return state;
  }
};

export default cartReducer;
