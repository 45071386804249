import { Card, CardBody, Button, Row, Col } from "reactstrap";
import { Tag, Descriptions } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getTaxModule } from "../helpers/taxmodule";
import React, { useEffect, useState } from "react";
import Container from "reactstrap/lib/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import {
  placeTableOnlineOrder,
  tableOrder_payment_hitting,
  payment_and_order_staus
} from "../actions";

import ModelComp from "./ModelComp";
import {
  removeExtraItemFromCart,
  removeItemFromCart
} from "../actions/cartActions";
let isPaymentSuccess = true;
const TableCheckout = (props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  let [tax, setTax] = useState(0);
  let [taxValue, setTaxValue] = useState(0);
  const isVippsPermission = useSelector(
    (state) => state.restaurant.additional.vippsPermission
  );

  const webpayment = useSelector((state) => state.restaurant.webpayment);
  const cartData = useSelector((state) => {
    return state.cartReducer;
  });

  const extraItemRemoved = (uId, id) => {
    dispatch(removeExtraItemFromCart({ uniqueId: uId, id }));
  };

  const onPlaceOrder = (isVipps) => {
    setIsLoading(true);
    const tableData = JSON.parse(localStorage.getItem("tableData"));
    if (!tableData) {
      props.history.replace("/restaurant/booktable/" + restaurant.slug);
    }
    localStorage.removeItem("Vipps");
    if (isVipps) {
      localStorage.setItem("Vipps", true);
    }
    dispatch(
      placeTableOnlineOrder(
        restaurant.id,
        order.userId,
        cartData.items,
        cartData.grandTotal,
        "table",
        tax,
        tableData
      )
    );
  };

  const restaurant = useSelector((state) => {
    return state.restaurant;
  });

  const order = useSelector((state) => {
    return state.order;
  });

  const onRemoveItem = (item) => {
    dispatch(removeItemFromCart(item._uniqueId));
  };
  useEffect(() => {
    console.log("--------webpayment", webpayment);
    const tableNumber = JSON.parse(localStorage.getItem("tableData"));
    const myTax = getTaxModule(restaurant.taxes, "table");
    console.log("myTax", myTax);

    setTax(myTax);
    if (!tableNumber) {
      props.history.replace("/restaurant/booktable/" + restaurant.slug);
    }
  }, []);

  useEffect(() => {
    setTaxValue((100 + Number(tax)) / 100);
  }, [tax]);

  useEffect(() => {
    if (order.orderSuccess) {
      // useDispatch()
      // isOrdered = false;
      var orderId = order.orderData;
      // var orderType = order.orderType;
      var crtAr = [];
      cartData.items.forEach((element) => {
        var crt = {
          id: element._id,
          line_id: element._uniqueId.toString(),
          description: element.name,
          quantity: element.quantity,
          amount: Number(element.price) * 100,
          vat_amount: Number(element.price),
          vat: tax
        };
        crtAr.push(crt);
      });

      dispatch(tableOrder_payment_hitting(orderId, cartData.grandTotal, crtAr));
    }
  }, [order.orderSuccess]);

  useEffect(() => {
    if (order.paymentHittingSuccess) {
      isPaymentSuccess = false;
      if (order.paymentHittingData.url) {
        dispatch(payment_and_order_staus());
        window.open(order.paymentHittingData.url, "_self");
      }
    }
  }, [order.paymentHittingSuccess]);

  return (
    <>
      <ModelComp isTrue={isLoading} />
      {cartData && cartData.items.length > 0 && tax && taxValue ? (
        <Container className="h-100 d-flex flex-column justify-content-between px-3">
          <Row
            className="px-3 py-2 justify-content-end position-fixed bg-white w-100"
            style={{ top: 0, zIndex: 99999999999 }}
          >
            <Button
              color="success"
              onClick={() => {
                props.history.replace(
                  "/restaurant/tableorder/" + restaurant.slug
                );
              }}
            >
              {" "}
              Go to menu
            </Button>
          </Row>
          <Row className="mainRowWrapper">
            {" "}
            <Col>
              {cartData.items.map((item) => {
                return (
                  <Card
                    className="mb-3 position-relative"
                    style={{
                      borderRadius: "10px"
                    }}
                  >
                    <Button
                      close
                      style={{ position: "absolute", right: "6px", top: 0 }}
                      onClick={() => onRemoveItem(item)}
                    />
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between"
                        }}
                      >
                        {/* {item.thumbnail ? (
                              <img
                                src={`${globalConst.s3url}${item.thumbnail}`}
                                width="70px"
                                height="70px"
                              />
                            ) : (
                              <img
                                width="70"
                                px
                                height="70px"
                                src={`${globalConst.s3url}${item.image}`}
                              />
                            )} */}
                        <p className="productTitle mb-0">{item.name}</p>{" "}
                        <p className="productTitle mb-0">
                          Kr.{item.totalPrice}
                        </p>
                        {/* <p className="productTitle">Kr.{item.totalPrice}</p> */}
                        {/* <div>
                            // {item.ingredientsNotRequired.length > 0 && (
                            //   <div>
                            //     ingredents:
                            //     {item.ingredientsNotRequired.map((item) => {
                            //       return (
                            //         <>
                            //           <p>{` ${item} not required`}</p>
                            //         </>
                            //       );
                            //     })}
                            //   </div>
                            // )}

                            {item.instructions != "" && (
                              <span>
                                {" "}
                                instructions:
                                {`${item.instructions}`}
                              </span>
                            )}
                            {item.spiciness && (
                              <div>Spiciness:{item.spiciness}</div>
                            )}
                            {item.extraItems.length > 0 && (
                              <div>
                                EXTRAS:
                                {item.extraItems.map((item1) => {
                                  return (
                                    <>
                                      <p>{` ${item1.name} kr.${
                                        item1.price
                                      }`}</p>
                                      <button
                                        onClick={() =>
                                          extraItemRemoved(
                                            item._uniqueId,
                                            item1.id
                                          )
                                        }
                                      >
                                        -
                                      </button>
                                    </>
                                  );
                                })}
                              </div>
                            )}
                          </div> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly"
                          }}
                        />
                        {/* <div className="addItems"
                              style={{
                                display: "flex",
                                height: "2rem",
                                // alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <div style={{}}>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onAddQuantity(item)}
                                >
                                  +
                                </span>
                                <span
                                  style={{
                                    margin: "0 9px",
                                    border: "1 solid black",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.quantity}
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onRemoveQuantity(item)}
                                >
                                  -
                                </span>
                              </div>
                            </div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px"
                        }}
                      >
                        {item.ingredientsNotRequired.length > 0 && (
                          <div>
                            <p
                              className="productTitle"
                              style={{ lineHeight: "0.5em" }}
                            >
                              ingredents:
                            </p>
                            {item.ingredientsNotRequired.map((item) => {
                              return (
                                <>
                                  <p className="ingredientTitle">{`Uten ${item}`}</p>
                                </>
                              );
                            })}
                          </div>
                        )}
                        <div>
                          {item.spiciness && (
                            <div className="productTitle">
                              Spiciness:
                              <br />
                              {item.spiciness}
                            </div>
                          )}
                          {/* {item.instructions != "" && (
                                <span>
                                  {" "}
                                  <p className="productTitle">instructions:</p>
                                  {`${item.instructions}`}
                                </span>
                              )} */}
                          {/* {item.spiciness && (
                                <div className="productTitle">
                                  Spiciness:{item.spiciness}
                                </div>
                              )} */}
                        </div>
                      </div>
                      {item.instructions !== "" && (
                        <span>
                          {" "}
                          <p
                            className="productTitle"
                            style={{ lineHeight: "0.5em" }}
                          >
                            instructions:
                          </p>
                          {`${item.instructions}`}
                        </span>
                      )}

                      {item.extraItems.length > 0 && (
                        <div>
                          <p className="productTitle">EXTRAS:</p>
                          {item.extraItems.map((item1) => {
                            return (
                              <div className="extraWrapper">
                                <Tag className="tagContainer">
                                  <p>{` ${item1.name} kr.${item1.price}`}</p>
                                  <button
                                    className="removebutton"
                                    onClick={() =>
                                      extraItemRemoved(
                                        item._uniqueId,
                                        item1._id
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                </Tag>
                                {/* <button className="removebutton"
                                        onClick={() =>
                                          extraItemRemoved(
                                            item._uniqueId,
                                            item1.id
                                          )
                                        }
                                      >
                                      -
                                      </button> */}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>

          <Row
            className="cartDescription bg-white p-2 position-fixed"
            style={{ width: "100vw", bottom: 0, zIndex: 99999999999 }}
          >
            <Descriptions title="Payment Summary" bordered className="w-100">
              <Descriptions.Item label="Subtotal">
                Kr.{" "}
                {(
                  cartData.grandTotal && cartData.grandTotal / taxValue
                ).toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label={`tax(${tax}%)`}>
                Kr.{" "}
                {(cartData.grandTotal - cartData.grandTotal / taxValue).toFixed(
                  2
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={`Total (${cartData.items.length} items`}
              >
                Kr. {cartData.grandTotal}
              </Descriptions.Item>
            </Descriptions>
            <div className="d-flex ButtonContainer w-100 justify-content-between mt-2">
              {webpayment !== "none" && (
                <div className="p-0">
                  <Button
                    color="success"
                    block
                    onClick={() => onPlaceOrder(false)}
                    className="tableOrderButton m-0"
                  >
                    Pay With Card Kr. {cartData.grandTotal}
                  </Button>
                </div>
              )}
              {/* {isVippsPermission && (
                <div className="ButtonPay m-0">
                  {" "}
                  <Button
                    color="warning"
                    block
                    onClick={() => onPlaceOrder(true)}
                    className="tableOrderButtonVipps vippssCard m-0"
                  >
                    Pay With Vipps Kr. {cartData.totalPrice}
                  </Button>
                </div>
              )} */}
            </div>
          </Row>

          {/* Check then delete below code otherwise not */}
          {/* <Row className="ButtonContainer">
            {webpayment !== "none" ? (
              <Col
                xl={isVippsPermission ? "4" : "8"}
                md={isVippsPermission ? "4" : "8"}
                sm={isVippsPermission ? "4" : "8"}
              >
                <Button
                  color="success"
                  block
                  onClick={() => onPlaceOrder(false)}
                  className="tableOrderButton"
                >
                  Pay With Card Kr. {cartData.grandTotal}
                </Button>
                <></>
              </Col>
            ) : (
              ""
            )}
            {isVippsPermission && (
              <Col
                xl={isVippsPermission ? "4" : "8"}
                md={isVippsPermission ? "4" : "8"}
                sm={isVippsPermission ? "4" : "8"}
                className="ButtonPay"
              >
                <Button
                  color="warning"
                  block
                  onClick={() => onPlaceOrder(true)}
                  className="tableOrderButtonVipps vippssCard"
                >
                  Pay With Vipps Kr. {cartData.totalPrice}
                </Button>
              </Col>
            )}
          </Row> */}
        </Container>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FontAwesomeIcon icon={faCartPlus} color="grey" size="2x" />
          <h5 style={{ margin: "20px 0" }}>Your Cart is Empty</h5>
          <Button
            color="success"
            onClick={() => {
              props.history.replace(
                "/restaurant/tableorder/" + restaurant.slug
              );
            }}
          >
            {" "}
            Go to menu
          </Button>
        </div>
      )}
    </>
  );
};

export default TableCheckout;
