import React, { useEffect } from "react";
import { Col, Row } from "antd";
import axios from "axios";
import globalConst from "../helpers/global";

export default function PaymentSuccess() {
  useEffect(() => {
    const currentPageUrl = window.location.href;
    console.log("Current Page Url", currentPageUrl);

    axios
      .post(`${globalConst.serverip}api/v1/public/reservetable`, {
        currentPageUrl,
      })
      .then((res) => {
        console.log("--------------res-data----------------------", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <Row>
      <Col span={24} className="mainCategory pt-5">
        <div style={{ backgroundColor: "white", padding: "60px 20px" }}>
          <h1 className="text-dark text-center" style={{ fontWeight: "bold" }}>
            Table Reserved Successfully
          </h1>
        </div>
      </Col>
    </Row>
  );
}
