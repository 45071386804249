import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import * as io from "socket.io-client";
import {
  removeFromCart,
  placeOnlineOrder,
  payment_hitting,
  removeExtraFromCart
} from "../actions";
import Header from "./Header";
import Delivery from "./Delivery";
import Pickup from "./Pickup";
import Cart from "./Cart";
import globalConst from "../helpers/global";
import { getTaxModule } from "../helpers/taxmodule";
import { socket } from "../helpers/globSocket";

class Checkout extends Component {
  removeItemFromCart = (id) => {
    this.props.dispatch(removeFromCart(id));
  };
  removeExtraFromCart = (proId, extraId, ix) => {
    this.props.dispatch(removeExtraFromCart({ proId, extraId, ix }));
  };

  constructor(props) {
    super(props);
    this.state = {
      socket: socket
    };
  }
  componentDidMount() {
    if (this.props.location.pathname === "/checkout/deliver") {
      this.setState({ orderType: "Drive Out" });
    } else {
      this.setState({ orderType: "Take away" });
    }
  }
  transactionSuccess_Socket = () => {
    socket.on("transactionsuccess", (data) => {
      alert("SUCCESS FINAL ");
    });
  };

  placeOrder = (isVipps) => {
    localStorage.removeItem("Vipps");
    if (isVipps) {
      localStorage.setItem("Vipps", true);
    } else {
      localStorage.removeItem("Vipps");
    }
    const { resId, userId, cart, totalPrice, taxModules } = this.props;
    let tax = getTaxModule(taxModules, "onlineOrder");

    if (this.props.location.pathname === "/checkout/deliver") {
      this.setState({ orderType: "Drive Out" });

      this.props.dispatch(
        placeOnlineOrder(resId, userId, cart, totalPrice, "deliver", tax)
      );
    } else {
      this.setState({ orderType: "Take away" });
      if (tax) {
        this.props.dispatch(
          placeOnlineOrder(resId, userId, cart, totalPrice, "pickup", tax)
        );
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.paymentHittingSuccess !== this.props.paymentHittingSuccess) {
      if (this.props.paymentHittingData.url) {
        window.open(this.props.paymentHittingData.url, "_self");
      }
    }
    if (prevProps.orderSuccess !== this.props.orderSuccess) {
      var orderId = this.props.orderData;
      var orderType = this.props.orderType;
      var dvCharge = this.props.driveoutCharge;
      var tax = this.props.tax;
      var { dispatch } = this.props;
      var crtAr = [];
      this.props.cart.forEach((element) => {
        var crt = {
          id: element._id,
          line_id: element.itemNo.toString(),
          description: element.instruction[0].productName,
          quantity: element.quantity,
          amount: Number(element.price) * 100,
          vat_amount: Number(element.price),
          vat: Number(tax)
        };
        crtAr.push(crt);
      });

      if (orderType === "Drive Out") {
        dispatch(
          payment_hitting(
            orderId,
            Number(this.props.totalPrice) + Number(dvCharge),
            crtAr
          )
        );
      } else {
        dispatch(payment_hitting(orderId, this.props.totalPrice, crtAr));
      }
    }
  }
  render() {
    const {
      userChecked,
      cart,
      totalPrice,
      totalQuantity,
      openDeliveryOptions,
      orderType,
      driveoutCharge
    } = this.props;

    return (
      <>
        {/* <Header /> */}
        <Container style={{ marginTop: "30px" }}>
          <Row>
            <Col md="8">
              <Switch>
                <Route path="/checkout/deliver" component={Delivery} />
                <Route path="/checkout/pickup" component={Pickup} />
              </Switch>
            </Col>
            {/* <Col md="4">
              <Cart
                cart={cart}
                totalPrice={totalPrice}
                totalQuantity={totalQuantity}
                openDeliveryOptions={openDeliveryOptions}
                taxes={this.props.taxModules}
                removeItemFromCart={(prodId) => this.removeItemFromCart(prodId)}
                removeExtraFromCart={(proId, extraId, ix) =>
                  this.removeExtraFromCart(proId, extraId, ix)
                }
                orderType={this.props.orderType}
                placeOrder={this.placeOrder}
                active={userChecked}
                driveoutCharge={driveoutCharge}
                button="Place Order"
              />
            </Col> */}
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ products, order, restaurant, cartReducer }) => {
  const { error, cart, totalQuantity, totalPrice } = products;
  const { grandTotal, totalQuantity: overAllQuantity } = cartReducer;

  const {
    userChecked,
    userId,
    activeId,
    orderType,
    paymentHittingLoading,
    paymentHittingSuccess,
    paymentHittingError,
    paymentHittingData,
    orderSuccess,
    orderData,
    tax
  } = order;
  const resId = restaurant.id;
  const driveoutCharge = restaurant.driveoutCharge;
  const taxModules = restaurant.taxes;

  return {
    error,
    cart,
    totalQuantity: totalQuantity ? totalQuantity : overAllQuantity,
    totalPrice: totalPrice ? totalPrice : grandTotal,
    orderType,
    resId,
    userChecked,
    userId,
    activeId,
    driveoutCharge,
    paymentHittingLoading,
    paymentHittingSuccess,
    paymentHittingError,
    paymentHittingData,
    orderSuccess,
    orderData,
    taxModules,
    tax
  };
};

export default withRouter(connect(mapStateToProps)(Checkout));
